import { ref } from "vue";
import {
    Resource,
    ResourceState,
    resourceStatePrototype,
    useResourceState,
} from "@/domains/app/resource";

export type Pursuit = Resource & {
    label: string;
};

// Pursuit ids related to dogs
export const PURSUIT_DOG_IDS = [3, 4, 5, 6];

export type PursuitState = ResourceState<Pursuit>;

/**
 * Create a pursuit state to get data and its mutators
 * @param id Id of the pursuit that the state should be related to
 * @returns a pursuit state with the data and mutators
 */
export function usePursuit(id?: number): PursuitState {
    const state: PursuitState = {
        data: ref(null),
        apiRoute: "pursuits",
        apiErrors: ref(null),
        save: () => resourceStatePrototype.save(state),
        del: () => resourceStatePrototype.del(state),
        init: () =>
            resourceStatePrototype.init(id, state, {
                id: -1,
                label: "",
            }),
    };

    return useResourceState(state);
}

import { ref } from "vue";
import {
    Resource,
    ResourceState,
    resourceStatePrototype,
    useResourceState,
} from "@/domains/app/resource";

export const ROLE_USER = "role-user";
export const ROLE_ADMIN = "role-admin";
export const ROLE_DELEGATE = "role-delegate";

export const ABILITY_READ_GLOBAL_CRUDS = "read-global-cruds";
export const ABILITY_WRITE_GLOBAL_CRUDS = "write-global-cruds";
export const ABILITY_READ_RESTRICTED_CRUDS = "read-restricted-cruds";
export const ABILITY_WRITE_RESTRICTED_CRUDS = "write-restricted-cruds";
export const ABILITY_READ_DEPS_CRUDS = "read-deps-cruds";
export const ABILITY_WRITE_DEPS_CRUDS = "write-deps-cruds";

export const ABILITY_WRITE_NEWS = "write-news";

export const ABILITY_READ_DEP_STATISTICS = "read-dep-stats";
export const ABILITY_READ_ALL_STATISTICS = "read-all-stats";

export type Ability =
    | typeof ABILITY_READ_GLOBAL_CRUDS
    | typeof ABILITY_WRITE_GLOBAL_CRUDS
    | typeof ABILITY_READ_RESTRICTED_CRUDS
    | typeof ABILITY_WRITE_RESTRICTED_CRUDS
    | typeof ABILITY_READ_DEPS_CRUDS
    | typeof ABILITY_WRITE_DEPS_CRUDS
    | typeof ABILITY_WRITE_NEWS
    | typeof ABILITY_READ_DEP_STATISTICS
    | typeof ABILITY_READ_ALL_STATISTICS;

export type User = Resource & {
    member_num: string;
    department_id: number;
    first_name: string;
    last_name: string;
    disabled: boolean;
    email: string | null;
    allow_paid_trips: boolean;
    roles: string[];
    abilities?: Ability[];
};

export type UserState = ResourceState<User>;

/**
 * Create a user state to get data and its mutators
 * @param id Id of the user that the state should be related to
 * @returns a user state with the data and mutators
 */
export function useUser(id?: number): UserState {
    const state: UserState = {
        data: ref(null),
        apiRoute: "users",
        apiErrors: ref(null),
        save: () => resourceStatePrototype.save(state),
        del: () => resourceStatePrototype.del(state),
        init: () =>
            resourceStatePrototype.init(id, state, {
                id: -1,
                department_id: -1,
                member_num: "",
                first_name: "",
                last_name: "",
                disabled: false,
                email: null,
                roles: [ROLE_USER],
                allow_paid_trips: true,
            }),
    };

    return useResourceState(state);
}

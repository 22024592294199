import { ref } from "vue";
import {
    Resource,
    ResourceState,
    resourceStatePrototype,
    useResourceState,
} from "@/domains/app/resource";

export type Ammunition = Resource & {
    label: string;
};

export type AmmunitionState = ResourceState<Ammunition>;

/**
 * Create a ammunition state to get data and its mutators
 * @param id Id of the ammunition that the state should be related to
 * @returns a ammunition state with the data and mutators
 */
export function useAmmunition(id?: number): AmmunitionState {
    const state: AmmunitionState = {
        data: ref(null),
        apiRoute: "ammunitions",
        apiErrors: ref(null),
        save: () => resourceStatePrototype.save(state),
        del: () => resourceStatePrototype.del(state),
        init: () =>
            resourceStatePrototype.init(id, state, {
                id: -1,
                label: "",
            }),
    };

    return useResourceState(state);
}

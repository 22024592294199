import { useUser } from "@/domains/auth/user";
import { Ability } from "@/domains/cruds/users/users";
import { ref, Ref } from "vue";
import { NavigationGuardWithThis, RouteLocationNormalized } from "vue-router";
import { getLoadingPromise } from "../loading";
import { useMetaTitle } from "../pageMeta";

const { user } = useUser();

export const afterLoginRoute: Ref<RouteLocationNormalized | null> = ref(null);

const { setTitle } = useMetaTitle();

export const middleware: NavigationGuardWithThis<undefined> = async (
    to,
    _from,
    next
) => {
    setTitle(null);

    if (!to.matched.length) {
        return next({ name: "error.404" });
    }

    if (!to.meta.gest && user.value) {
        afterLoginRoute.value = null;
    } else if (!user.value) {
        await getLoadingPromise("fetch-user-from-localstorage");
    }
    if (to.meta.abilities && user.value) {
        const isAllowed = (to.meta.abilities as Ability[]).every(
            (requiredAbility) =>
                user.value?.abilities?.includes(requiredAbility) || false
        );
        if (!isAllowed) {
            return next({ name: "error.403" });
        }
    }
    if (to.meta.canAccessCb && !(to.meta as any).canAccessCb(user.value)) {
        return next({ name: "error.403" });
    }
    if (to.meta.gest || user.value) {
        return next();
    }
    afterLoginRoute.value = to;
    next({ name: "auth.login" });
};

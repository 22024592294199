import { computed, Ref, WritableComputedRef } from "vue";
import {
    FormFields,
    FormParams,
    useRelationAsFieldValue,
} from "@/domains/app/forms";
import { Weapon, useWeapon } from "./weapons";
import { createFieldState } from "@/domains/app/forms";
import { useUser } from "@/domains/auth/user";
import { ROLE_ADMIN } from "../users/users";

export type WeaponFormState = {
    formArgs: FormParams;
    data: Ref<Weapon | null>;
};

/**
 * Create form state descibing how to structure form with its configuration
 *
 * @param id id of the weapon in the form
 * @returns a form state
 */
export function useWeaponForm(id?: number): WeaponFormState {
    const { data: weapon, save, del: remove, apiErrors } = useWeapon(id);

    const fields = getFields(weapon);

    const formArgs: FormParams = {
        title: "Création d'une arme",
        driver: {
            submit: save,
        },
        fields,
        getErrors: () => apiErrors,
    };

    // If Edition mode
    if (id !== undefined && id >= 0) {
        formArgs.title = "Edition d'une arme";
        formArgs.driver.delete = remove;
        const user = useUser().user;
        formArgs.getDisabled = () =>
            computed(
                () =>
                    weapon.value !== null &&
                    weapon.value.id > 0 &&
                    user.value !== null &&
                    user.value.id !== weapon.value.user_id &&
                    !user.value.roles.includes(ROLE_ADMIN)
            );
    }

    const formState: WeaponFormState = {
        formArgs,
        data: weapon,
    };

    return formState;
}

/**
 * Map weapon props to form fields
 *
 * @param weapon weapon state
 * @returns form fields
 */
function getFields(weapon: Ref<Weapon | null>): FormFields {
    const labelState: WritableComputedRef<string> = computed<string>({
        get: () => (weapon.value ? weapon.value.label : ""),
        set: (val: string) => {
            if (weapon.value) weapon.value.label = val;
        },
    });

    return [
        {
            label: "Label de l'arme",
            type: "text",
            name: "label",
            getState: () => labelState,
        },
        {
            label: "Type de l'arme",
            type: "choice",
            name: "type_id",
            getAllowedValues: () =>
                useRelationAsFieldValue("weapon-types", "label"),
            getState: () => createFieldState<number>(weapon, "type_id"),
        },
    ];
}

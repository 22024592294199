import { ref } from "vue";
import {
    Resource,
    ResourceState,
    resourceStatePrototype,
    useResourceState,
} from "@/domains/app/resource";

export type Pace = Resource & {
    label: string;
};

export type PaceState = ResourceState<Pace>;

/**
 * Create a pace state to get data and its mutators
 * @param id Id of the pace that the state should be related to
 * @returns a pace state with the data and mutators
 */
export function usePace(id?: number): PaceState {
    const state: PaceState = {
        data: ref(null),
        apiRoute: "paces",
        apiErrors: ref(null),
        save: () => resourceStatePrototype.save(state),
        del: () => resourceStatePrototype.del(state),
        init: () =>
            resourceStatePrototype.init(id, state, {
                id: -1,
                label: "",
            }),
    };

    return useResourceState(state);
}

import { computed, Ref, WritableComputedRef } from "vue";
import { FormFields, FormParams } from "@/domains/app/forms";
import { Result, useResult } from "./results";

export type ResultFormState = {
    formArgs: FormParams;
    data: Ref<Result | null>;
};

/**
 * Create form state descibing how to structure form with its configuration
 *
 * @param id id of the result in the form
 * @returns a form state
 */
export function useResultForm(id?: number): ResultFormState {
    const { data: result, save, del: remove, apiErrors } = useResult(id);

    const fields = getFields(result);

    const formArgs: FormParams = {
        title: "Création d'un résultat de fiche",
        driver: {
            submit: save,
        },
        fields,
        getErrors: () => apiErrors,
    };

    // If Edition mode
    if (id !== undefined && id >= 0) {
        formArgs.title = "Edition d'un résultat";
        formArgs.driver.delete = remove;
    }

    const formState: ResultFormState = {
        formArgs,
        data: result,
    };

    return formState;
}

/**
 * Map result props to form fields
 *
 * @param result result state
 * @returns form fields
 */
function getFields(result: Ref<Result | null>): FormFields {
    const labelState: WritableComputedRef<string> = computed<string>({
        get: () => (result.value ? result.value.label : ""),
        set: (val: string) => {
            if (result.value) result.value.label = val;
        },
    });

    return [
        {
            label: "Nom du résultat",
            type: "text",
            name: "label",
            getState: () => labelState,
        },
    ];
}

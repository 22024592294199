
import { defineComponent, PropType, UnwrapRef } from "@vue/runtime-core";
import { StaticFieldConfig } from "../forms";
import { ComputedRef, Ref } from "vue";

export default defineComponent({
    props: {
        config: {
            type: Object as PropType<UnwrapRef<StaticFieldConfig>>,
            required: true,
        },
    },
    setup(props) {
        return {
            show: props.config.getVisibility
                ? props.config.getVisibility()
                : true,
            getValue(v: null | Ref<string> | ComputedRef<string> | string) {
                if (v === null) return "";
                if (typeof v === "string") return v;
                return v.value;
            },
        };
    },
});

import { createApp } from "vue";
import App from "./App.vue";
import router from "./domains/app/router/router";

// styles
import "@/styles/app.scss";
import { initializeUserFromLocalStorage } from "./domains/auth/user";
import { loadingDirective } from "./domains/app/loading";

initializeUserFromLocalStorage();

const app = createApp(App);

app.directive("loading-key", loadingDirective);

app.use(router).mount("#app");

import { computed, Ref, WritableComputedRef } from "vue";
import { FormFields, FormParams } from "@/domains/app/forms";
import { WeaponType, useWeaponType } from "./weaponTypes";

export type WeaponTypeFormState = {
    formArgs: FormParams;
    data: Ref<WeaponType | null>;
};

/**
 * Create form state descibing how to structure form with its configuration
 *
 * @param id id of the weaponType in the form
 * @returns a form state
 */
export function useWeaponTypeForm(id?: number): WeaponTypeFormState {
    const {
        data: weaponType,
        save,
        del: remove,
        apiErrors,
    } = useWeaponType(id);

    const fields = getFields(weaponType);

    const formArgs: FormParams = {
        title: "Création d'un type d'arme",
        driver: {
            submit: save,
        },
        fields,
        getErrors: () => apiErrors,
    };

    // If Edition mode
    if (id !== undefined && id >= 0) {
        formArgs.title = "Edition d'un type d'arme";
        formArgs.driver.delete = remove;
    }

    const formState: WeaponTypeFormState = {
        formArgs,
        data: weaponType,
    };

    return formState;
}

/**
 * Map weaponType props to form fields
 *
 * @param dep weaponType state
 * @returns form fields
 */
function getFields(dep: Ref<WeaponType | null>): FormFields {
    const labelState: WritableComputedRef<string> = computed<string>({
        get: () => (dep.value ? dep.value.label : ""),
        set: (val: string) => {
            if (dep.value) dep.value.label = val;
        },
    });

    return [
        {
            label: "Label du tir",
            type: "text",
            name: "label",
            getState: () => labelState,
        },
    ];
}

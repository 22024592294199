
import { defineComponent, PropType } from "@vue/runtime-core";
import { DateFieldFormats } from "../forms";

export default defineComponent({
    props: {
        format: {
            type: String as PropType<DateFieldFormats>,
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
    },
    setup(props, { emit }) {
        function validateTime(value: string) {
            if (
                props.format == "time:h:m" &&
                !value.match(/^(([0-1][0-9])|(2[0-3])):[0-5][0-9]$/)
            ) {
                return "00:00";
            }
            return value;
        }

        function formatNumber(
            v: string,
            maxLength?: number,
            minLength?: number
        ) {
            const matches = v.match(/[1-9][0-9]*/);

            v = matches ? matches[0] : "";
            if (minLength && v.length < minLength) {
                v = "0".repeat(minLength - v.length) + v;
            } else if (maxLength && v.length > maxLength) {
                if (v[0] == "0") {
                    v = v.slice(1, 3);
                }
                v = v.slice(0, 2);
            }
            return v;
        }

        return {
            onMinutesUpdated: (evt: Event) => {
                const val = (evt.target as HTMLInputElement).value;
                const value = validateTime(props.value);
                const hours = value.split(":")[0];
                emit(
                    "update:value",
                    `${hours}:${formatNumber(
                        parseInt(val) >= 60 ? "59" : val,
                        2,
                        2
                    )}`
                );
            },
            onHoursUpdated: (evt: Event) => {
                const val = (evt.target as HTMLInputElement).value;
                if (props.format == "time:h:m") {
                    const curr = validateTime(props.value);
                    const minutes = curr.split(":")[1];
                    return emit(
                        "update:value",
                        `${formatNumber(val, 2, 2)}:${minutes}`
                    );
                }
                return emit("update:value", formatNumber(val));
            },
            onDateUpdated: (evt: Event) =>
                emit("update:value", (evt.target as HTMLInputElement).value),
            formatDate: (date: string) => date.split(" ")[0],

            onNumberFocusIn(e: FocusEvent) {
                const target = e.target as HTMLInputElement;
                if (target.value === target.placeholder) {
                    target.value = "";
                }
            },
            onNumberFocusOut(e: FocusEvent) {
                const target = e.target as HTMLInputElement;
                if (target.value === "") {
                    target.value = target.placeholder;
                }
            },
        };
    },
});

import { useLoading } from "@/domains/app/loading";
import { Resource } from "@/domains/app/resource";
import { ErrorHandler, request as makeApiRequest } from "./api";

/**
 * Fetch a resource from the API
 * @param id
 * @param errorHandler
 * @returns resource resource from API
 */
export function fetchResource<T extends Resource>(
    routeURI: string,
    id: number,
    errorHandler: ErrorHandler
): Promise<T | null> {
    return makeApiRequest<T>(`${routeURI}/${id}`, "get", errorHandler);
}

/**
 * Create a new resource
 * @param dep
 * @param errorHandler
 * @returns resource resource from API
 */
export function createResource<T extends Resource>(
    routeURI: string,
    resource: T,
    errorHandler: ErrorHandler
): Promise<T | null> {
    const setLoading = useLoading("resource-create").setLoading;
    setLoading(true);
    return makeApiRequest<T>(routeURI, "post", errorHandler, resource).finally(
        () => {
            setLoading(false);
        }
    );
}

/**
 * Update a resource
 * @param resource
 * @param errorHandler
 * @returns resource resource from API
 */
export function updateResource<T extends Resource>(
    routeURI: string,
    resource: T,
    errorHandler: ErrorHandler
): Promise<T | null> {
    const setLoading = useLoading("resource-update").setLoading;
    return makeApiRequest<T>(
        `${routeURI}/${resource.id}`,
        "put",
        errorHandler,
        resource
    ).then((rs) => {
        setLoading(false);
        return rs;
    });
}

/**
 * Delete a resource from the api
 * @param resource
 * @param errorHandler
 */
export function deleteResource<T extends Resource>(
    routeURI: string,
    resource: T,
    errorHandler: ErrorHandler
): Promise<T | null> {
    const setLoading = useLoading("resource-delete").setLoading;
    return makeApiRequest<T>(
        `${routeURI}/${resource.id}`,
        "delete",
        errorHandler
    ).then((rs) => {
        setLoading(false);
        return rs;
    });
}

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ms-1 mt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createBlock(_component_Form, _mergeProps(_ctx.formParams, { "can-submit": _ctx.isFormValid }), {
    indications: _withCtx(() => [
      _createElementVNode("ul", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.requirements, (req) => {
          return (_openBlock(), _createElementBlock("li", {
            style: {"list-style":"disc"},
            class: _normalizeClass({
                        'clr-valid': req.valid.value,
                        'clr-danger': !req.valid.value,
                    }),
            key: req.label
          }, _toDisplayString(req.label), 3))
        }), 128))
      ])
    ]),
    _: 1
  }, 16, ["can-submit"]))
}

import {
    computed,
    defineComponent,
    PropType,
    watch,
    ref,
} from "@vue/runtime-core";
import { WritableComputedRef } from "@vue/reactivity";
import {
    FieldConfig,
    NULL_VALUE_UID,
    PrioritizedValue,
    AllowedValuesType,
    ADD_RELATION_VALUE_UID,
} from "../forms";
import DateField from "./DateField.vue";
import ChoiceMultiplePrioritizedField from "./ChoiceMultiplePrioritizedField.vue";
import Tooltip from "./Tooltip.vue";
import { dispatch } from "../bus";
import PaginatedSelectField from "./PaginatedSelectField.vue";

export default defineComponent({
    components: {
        DateField,
        ChoiceMultiplePrioritizedField,
        Tooltip,
        PaginatedSelectField,
    },
    props: {
        config: { type: Object as PropType<FieldConfig<any>>, required: true },
        errors: Object as PropType<null | string[]>,
    },
    setup(props) {
        let desktopMode = false;

        const allowedValues = props.config.getAllowedValues
            ? props.config.getAllowedValues()
            : undefined;
        const choiceValues = computed<AllowedValuesType["value"]>(() => {
            return allowedValues?.value || [];
        });

        const state = props.config?.getState();

        const visibility =
            props.config.getVisibility !== undefined
                ? props.config.getVisibility()
                : computed(() => true);

        // Set value to null if not visible
        watch(
            visibility,
            (show) => {
                function copyValue(v: any) {
                    return Array.isArray(v)
                        ? [...v]
                        : v !== null && typeof v === "object"
                        ? { ...v }
                        : v;
                }

                if (!show) {
                    state.value = copyValue(props.config.defaultHidden);
                } else if (state.value === props.config.defaultHidden && show) {
                    if (props.config.defaultVisible instanceof Function) {
                        state.value = copyValue(
                            props.config.defaultVisible().value
                        );
                    } else {
                        state.value = copyValue(props.config.defaultVisible);
                    }
                }
            },
            {
                immediate: true,
            }
        );

        function isPrioritizedValue(v: any): v is PrioritizedValue {
            return (
                props.config.type === "choice-multiple-prioritized" &&
                typeof state.value === "object"
            );
        }

        type RealFieldValue = string | number | string[] | undefined;
        // Writtable ref passed to html inputs to handle data translation before editing state
        const fieldModel: WritableComputedRef<RealFieldValue> =
            computed<RealFieldValue>({
                set: (val: RealFieldValue | null) => {
                    if (isPrioritizedValue(state.value)) return;

                    if (!Array.isArray(val)) {
                        if (
                            val === ADD_RELATION_VALUE_UID &&
                            includeCreateRelationChoice(props.config)
                        ) {
                            triggerCreateRelation(props.config);
                            val = null;
                            if (allowedValues) {
                                // Hack to select option and then remove it from options to unselect option
                                const o = { key: -1, value: "" };
                                allowedValues.value?.push(o);
                                setTimeout(
                                    () =>
                                        (allowedValues.value =
                                            allowedValues.value?.filter(
                                                (opt) => opt.key !== o.key
                                            ) || null),
                                    0
                                );
                            }
                        }
                        state.value = val === undefined ? null : val;
                    } else if (
                        desktopMode &&
                        val.length === 1 &&
                        Array.isArray(state.value)
                    ) {
                        const delta = parseInt(val[0]);
                        if (state.value.includes(delta)) {
                            state.value = state.value.filter(
                                (v) => v !== delta
                            );
                        } else {
                            state.value = [...state.value, delta];
                        }
                    } else {
                        state.value = val.map((val) => parseInt(val));
                    }
                },
                get: () =>
                    state.value === true
                        ? 1
                        : state.value === false
                        ? 0
                        : state.value === null ||
                          isPrioritizedValue(state.value)
                        ? undefined
                        : Array.isArray(state.value)
                        ? state.value.map((val) => val.toString())
                        : state.value,
            });

        // Writtable ref passed to html checkbox to handle data translation before editing state
        type Booleanish = "true" | "false" | true | false;
        const boolModel = computed<Booleanish>({
            get: () => state.value === true,
            set: (val: Booleanish) => {
                state.value = val === true || val === "true";
            },
        });

        const timeModel = computed<string>({
            get: () => {
                if (typeof state.value === "string") {
                    return state.value;
                }
                return "";
            },
            set: (val: string) => {
                state.value = val;
            },
        });

        const prioritizedChoicesModel = computed<PrioritizedValue>({
            get: () => {
                if (
                    props.config.type === "choice-multiple-prioritized" &&
                    Array.isArray(state.value)
                ) {
                    return (state.value as any as PrioritizedValue).sort(
                        (a, b) => (a.priority || 0) - (b.priority || 0)
                    );
                }
                return [];
            },
            set: (val: PrioritizedValue) => {
                state.value = val;
            },
        });

        const includeCreateRelationChoice = (config: typeof props["config"]) =>
            (config.type === "choice" ||
                config.type === "choice-multiple" ||
                config.type === "choice-multiple-prioritized") &&
            config.isRelation;

        function triggerCreateRelation(config: typeof props["config"]) {
            if (
                config.type === "choice" ||
                config.type === "choice-multiple" ||
                (config.type === "choice-multiple-prioritized" &&
                    config.isRelation)
            ) {
                const factory = config.formFactory;
                if (factory) {
                    dispatch("field.create.relation", { factory });
                }
            }
        }
        return {
            markAsRequired:
                typeof props.config.markAsRequired === "boolean" ||
                !props.config.markAsRequired
                    ? props.config.markAsRequired
                    : props.config.markAsRequired(),
            ADD_RELATION_VALUE_UID,
            state: fieldModel,
            boolModel,
            timeModel,
            prioritizedChoicesModel,
            choiceValues,
            visibility,
            NULL_VALUE_UID,
            warning: props.config.getWarning ? props.config.getWarning() : null,
            includeCreateRelationChoice,
            triggerCreateRelation,
            showPassword: ref(false),
            setDesktopMode: (b: boolean) => (desktopMode = b),
            onNumberFocusIn(e: FocusEvent) {
                const target = e.target as HTMLInputElement;
                if (target.value === "0") {
                    target.value = "";
                }
            },
            onNumberFocusOut(e: FocusEvent) {
                const target = e.target as HTMLInputElement;
                if (target.value === "") {
                    target.value = "0";
                }
            },
        };
    },
});

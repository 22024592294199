import { ref } from "vue";
import {
    Resource,
    ResourceState,
    resourceStatePrototype,
    useResourceState,
} from "@/domains/app/resource";

export type Faq = Resource & {
    question: string;
    answer: string;
};

export type FaqState = ResourceState<Faq>;

/**
 * Create a faq state to get data and its mutators
 * @param id Id of the faq that the state should be related to
 * @returns a faq state with the data and mutators
 */
export function useFaq(id?: number): FaqState {
    const state: FaqState = {
        data: ref(null),
        apiRoute: "faqs",
        apiErrors: ref(null),
        save: () => resourceStatePrototype.save(state),
        del: () => resourceStatePrototype.del(state),
        init: () =>
            resourceStatePrototype.init(id, state, {
                id: -1,
                question: "",
                answer: "",
            }),
    };

    return useResourceState(state);
}

const listeners: Record<string, Array<(arg: any) => void>> = {};

export function onMessage<T>(
    messageKey: string,
    callback: (arg: T) => void
): () => void {
    if (!listeners[messageKey]) {
        listeners[messageKey] = [];
    }
    listeners[messageKey].push(callback);
    return () => {
        listeners[messageKey] = listeners[messageKey]?.filter(
            (c) => c !== callback
        );
    };
}

export function dispatch<T>(messageKey: string, data?: T): void {
    if (listeners[messageKey]) {
        listeners[messageKey].forEach((callback) => {
            callback(data);
        });
    }
}

import { ref } from "vue";
import {
    Resource,
    ResourceState,
    resourceStatePrototype,
    useResourceState,
} from "@/domains/app/resource";

export const ADMINISATRATION_LEVEL_USER = 0;
export const ADMINISATRATION_LEVEL_DELEGATE = 1;

export type Department = Resource & {
    label: string;
    code: string;
    administration_level:
        | typeof ADMINISATRATION_LEVEL_USER
        | typeof ADMINISATRATION_LEVEL_DELEGATE;
};

export type DepartmentState = ResourceState<Department>;

/**
 * Create a department state to get data and its mutators
 * @param id Id of the department that the state should be related to
 * @returns a department state with the data and mutators
 */
export function useDepartment(id?: number): DepartmentState {
    const state: DepartmentState = {
        data: ref(null),
        apiRoute: "departments",
        apiErrors: ref(null),
        save: () => resourceStatePrototype.save(state),
        del: () => resourceStatePrototype.del(state),
        init: () =>
            resourceStatePrototype.init(id, state, {
                id: -1,
                label: "",
                code: "",
                administration_level: ADMINISATRATION_LEVEL_DELEGATE,
            }),
    };

    return useResourceState(state);
}

import { ref } from "vue";
import {
    Resource,
    ResourceState,
    resourceStatePrototype,
    useResourceState,
} from "@/domains/app/resource";

export type Shot = Resource & {
    label: string;
    enabled: boolean;
};

export type ShotState = ResourceState<Shot>;

/**
 * Create a shot state to get data and its mutators
 * @param id Id of the shot that the state should be related to
 * @returns a shot state with the data and mutators
 */
export function useShot(id?: number): ShotState {
    const state: ShotState = {
        data: ref(null),
        apiRoute: "shots",
        apiErrors: ref(null),
        save: () => resourceStatePrototype.save(state),
        del: () => resourceStatePrototype.del(state),
        init: () =>
            resourceStatePrototype.init(id, state, {
                id: -1,
                label: "",
                enabled: true,
            }),
    };

    return useResourceState(state);
}

import { computed, ComputedRef, Ref, ref } from "vue";

export type FlashesState = {
    flashes: ComputedRef<Array<Flash>>;
    addFlash: (m: string, t?: FlashType, d?: number) => void;
    dismiss: (f: Flash) => void;
};

export type Flash = {
    type: FlashType;
    message: string;
    duration: number;
};

export type FlashType = "error" | "success";

const flashes: Ref<Array<Flash>> = ref([]);

export const useFlashes = (): FlashesState => ({
    flashes: computed(() => flashes.value), // force read only
    addFlash,
    dismiss: removeFlash,
});

function removeFlash(f: Flash) {
    const i = flashes.value.findIndex(
        (e) => e.message === f.message && e.type === f.type
    );
    if (i >= 0) flashes.value.splice(i);
}

function addFlash(message: string, type: FlashType = "error", duration = 3000) {
    const existing = flashes.value.findIndex(
        (o) => o.message === message && o.type === type
    );
    if (existing >= 0) return;

    const o = {
        type,
        message,
        duration,
    };
    flashes.value.push(o);
    setTimeout(() => removeFlash(o), duration);
}

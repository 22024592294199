import { ref } from "vue";
import {
    Resource,
    ResourceState,
    resourceStatePrototype,
    useResourceState,
} from "@/domains/app/resource";

export type Hunt = Resource & {
    label: string;
};

export type HuntState = ResourceState<Hunt>;

export const SHOT_REQUIRED_FOR_HUNT = [1, 2, 3, 4, 7, 8];
export const DISTURBING_ALLOWED_FOR_HUNT = [1];

/**
 * Create a hunt state to get data and its mutators
 * @param id Id of the hunt that the state should be related to
 * @returns a hunt state with the data and mutators
 */
export function useHunt(id?: number): HuntState {
    const state: HuntState = {
        data: ref(null),
        apiRoute: "hunts",
        apiErrors: ref(null),
        save: () => resourceStatePrototype.save(state),
        del: () => resourceStatePrototype.del(state),
        init: () =>
            resourceStatePrototype.init(id, state, {
                id: -1,
                label: "",
            }),
    };

    return useResourceState(state);
}

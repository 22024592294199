import { ref } from "vue";
import {
    Resource,
    ResourceState,
    resourceStatePrototype,
    useResourceState,
} from "@/domains/app/resource";
import { useUser } from "@/domains/auth/user";

export type Gic = Resource & {
    label: string;
    department_id: number | null;
    user_id: number;
};

export type GicState = ResourceState<Gic>;

/**
 * Create a gic state to get data and its mutators
 * @param id Id of the gic that the state should be related to
 * @returns a gic state with the data and mutators
 */
export function useGic(id?: number): GicState {
    const state: GicState = {
        data: ref(null),
        apiRoute: "gics",
        apiErrors: ref(null),
        save: () => resourceStatePrototype.save(state),
        del: () => resourceStatePrototype.del(state),
        init: () =>
            resourceStatePrototype.init(id, state, {
                id: -1,
                label: "",
                department_id: null,
                user_id: useUser().user.value?.id || -1,
            }),
    };

    return useResourceState(state);
}

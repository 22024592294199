export function downloadDocument(filename: string, data: string): void {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    a.href = "data:application/pdf;base64," + data;
    a.download = filename;
    a.click();
    a.remove();
}

export function downloadBlob(data: string, filename: string): void {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
}

import { computed, ComputedRef, ref, Ref, UnwrapRef, watch } from "vue";
import { FormErrors } from "./forms";
import {
    createResource as ApiCreateResource,
    updateResource as ApiUpdateResource,
    deleteResource as ApiDeleteResource,
    fetchResource,
} from "../api/crudOperations";
import { request } from "@/domains/api/api";
import { useLoading } from "./loading";
import { dispatch } from "./bus";

/**
 * A resource stored in API
 */
export interface Resource extends ResourceBase {
    [key: string]: unknown;
}

export type ResourceBase = {
    id: number; // -1 -> not persited in db yet
};

/**
 * Object with method to mutate a state
 */
export type ResourceStatePrototype = {
    save: <T extends ResourceBase>(state: ResourceState<T>) => Promise<void>;
    del: <T extends ResourceBase>(state: ResourceState<T>) => Promise<void>;
    init: <T extends ResourceBase>(
        id: number | undefined,
        state: ResourceState<T>,
        defaultDataValue: T
    ) => Promise<void>;
};

/**
 * State of a resource, contains general information and mutator to modify data
 */
export type ResourceState<T extends ResourceBase> = {
    data: Ref<T | null>;
    apiRoute: string;
    apiErrors: Ref<FormErrors | null>;
    save: () => Promise<void>;
    del: () => Promise<void>;
    init: () => Promise<void>;
};

/**
 * State of mutiple resources
 */
export type ResourcesState<T extends ResourceBase> = {
    data: Ref<UnwrapRef<T[]>>;
    search?: (payload: string) => Promise<void>;
};

export type PaginatedResourcesState<T extends ResourceBase> = {
    data: Ref<UnwrapRef<T[]>>;
    nextPage: () => Promise<void>;
    prevPage: () => Promise<void>;
    toPage: (number: number) => Promise<void>;
    refresh: () => Promise<void>;
    hasNextPage: ComputedRef<boolean | null>;
    loadNextPage: () => Promise<void>;
    total: Ref<number | null>;
    search?: (payload: string) => Promise<void>;
    canCreate: ComputedRef<boolean>;
};

export const itemsPerPage = 10;

/**
 * Save a resource in the database
 *
 * @param state State containing resource to save
 */
async function save<T extends ResourceBase>(
    state: ResourceState<T>
): Promise<void> {
    if (!state.data.value) {
        throw "Cannot save incomplete resource";
    }

    if (state.data.value?.id && state.data.value.id > 0) {
        // If resource id => update
        await ApiUpdateResource(
            state.apiRoute,
            state.data.value,
            (errs: FormErrors) => handleErrors(errs, state)
        );
    } else if (
        state.data.value !== null &&
        (state.data.value.id < 0 || state.data.value.id === undefined)
    ) {
        // If no resource id => create
        state.data.value = await ApiCreateResource(
            state.apiRoute,
            state.data.value,
            (errs: FormErrors) => handleErrors(errs, state)
        );

        dispatch(`relation.${state.apiRoute}.created`, state.data.value);
    }
    // Else resource has been deleted
}

/**
 * Delete a resource from the database
 *
 * @param state State containing resource to delete
 */
async function deleteResource<T extends ResourceBase>(
    state: ResourceState<T>
): Promise<void> {
    if (!state.data.value) {
        throw "Cannot delete incomplete resource";
    }
    if (state.data.value.id >= 0) {
        await ApiDeleteResource(
            state.apiRoute,
            state.data.value,
            (errs: FormErrors) => handleErrors(errs, state)
        );
        state.data.value = null;
    }
}

/**
 * Initialize a resource with its ID, if no id or -1, use default data otherwise fetch from API
 * @param id Id of the resource
 * @param state State to store resource
 * @param defaultDataValue Data value if resource not yet in db
 */
function initResource<T extends ResourceBase>(
    id: number | undefined,
    state: ResourceState<T>,
    defaultDataValue: T
): Promise<void> {
    if (id === undefined || id < 0) {
        state.data.value = defaultDataValue;
        return Promise.resolve();
    } else {
        return fetchResource<T>(state.apiRoute, id, (errs) =>
            handleErrors(errs, state)
        )
            .then((data) => (state.data.value = data))
            .catch((e) => {
                state.data.value = null;
                throw e;
            })
            .then(() => Promise.resolve());
    }
}

/**
 * Central point for all app states, initialize a given state
 *
 * @param fromState State declaration to create from
 * @returns Initialized state
 */
export function useResourceState<T extends ResourceBase>(
    fromState: ResourceState<T>
): ResourceState<T> {
    fromState.init();
    return fromState;
}

/**
 * Get resources from api
 *
 * @param apiIndexRoute route to get resources from
 * @returns state with resources
 */
export function usePaginatedResourcesState<T extends ResourceBase>(
    apiIndexRoute: string,
    searchable = false,
    queryParams: ComputedRef<Record<string, string>> | undefined = undefined
): PaginatedResourcesState<T> {
    const resources = ref<T[]>([]);

    const start = ref(0);

    const total = ref<null | number>(null);

    const { setLoading } = useLoading(`${apiIndexRoute}.loading.list`);

    const canCreate = ref(true);
    function fetchPage(search?: string, removePrevData = true) {
        setLoading(true);

        // If did a previous search and navigating throught results pages
        if (search === undefined && lastSearch) {
            search = lastSearch;
        }
        lastSearch = search ?? "";

        const params: Record<string, string> = {
            ...queryParams?.value,
            limit: "" + itemsPerPage,
            offset: "" + start.value,
        };

        if (search !== undefined) {
            params.search = encodeURIComponent(search);
        }

        return request<{ data: T[]; total: number; canCreate?: boolean }>(
            apiIndexRoute,
            "get",
            undefined,
            params
        )
            .then((data) => {
                if (data !== null) {
                    const wrapped = ref(data);
                    if (removePrevData) {
                        resources.value = wrapped.value.data;
                    } else {
                        resources.value.push(...wrapped.value.data);
                    }
                    total.value = wrapped.value.total;
                    if (data.canCreate !== undefined)
                        canCreate.value = data.canCreate;
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    let lastSearch: string | null = null;
    fetchPage();

    const search: PaginatedResourcesState<T>["search"] = searchable
        ? (payload: string) =>
              lastSearch !== payload ? fetchPage(payload) : Promise.resolve()
        : undefined;

    if (queryParams)
        watch(queryParams, () => {
            lastSearch ? fetchPage(lastSearch, true) : fetchPage();
        });

    return {
        data: resources,
        total,
        canCreate: computed(() => canCreate.value),
        search,
        nextPage: async () => {
            if (start.value + itemsPerPage >= (total.value || 0)) return;
            start.value += itemsPerPage;
            await fetchPage();
        },
        prevPage: async () => {
            if (start.value - itemsPerPage < 0) return;
            start.value -= itemsPerPage;
            await fetchPage();
        },
        toPage: async (page: number) => {
            start.value = Math.max(
                Math.min(itemsPerPage * (page - 1), total.value || 0),
                0
            );
            await fetchPage();
        },
        loadNextPage: async () => {
            if (start.value + itemsPerPage >= (total.value || 0)) return;
            start.value += itemsPerPage;
            await fetchPage(undefined, false);
        },
        hasNextPage: computed(
            () => start.value + itemsPerPage < (total.value || 0)
        ),
        refresh: fetchPage,
    };
}

/**
 * Get resources from api
 *
 * @param apiIndexRoute route to get resources from
 * @returns state with resources
 */
export function useResourcesState<T extends ResourceBase>(
    apiIndexRoute: string,
    searchable = false,
    queryParams: ComputedRef<Record<string, string>> | undefined = undefined
): ResourcesState<T> {
    const resources = ref<T[]>([]);

    const { setLoading } = useLoading(`${apiIndexRoute}.loading.list`);

    function fetchPage(search?: string) {
        setLoading(true);
        lastSearch = search ?? "";

        const params: Record<string, string> = { ...queryParams?.value };
        if (search !== undefined) {
            params.search = encodeURIComponent(search);
        }

        return request<T[]>(apiIndexRoute, "get", undefined, params)
            .then((data) => {
                if (data !== null) {
                    const wrapped = ref(data);
                    resources.value = wrapped.value;
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    let lastSearch: string | null = null;
    const search: PaginatedResourcesState<T>["search"] = searchable
        ? (payload: string) =>
              lastSearch !== payload ? fetchPage(payload) : Promise.resolve()
        : undefined;
    fetchPage();

    if (queryParams)
        watch(queryParams, () => {
            lastSearch ? fetchPage(lastSearch) : fetchPage();
        });

    return {
        data: resources,
        search,
    };
}

/**
 * Template methods that can be used to save a resource contained in a state
 */
export const resourceStatePrototype: ResourceStatePrototype = {
    save,
    del: deleteResource,
    init: initResource,
};

/**
 * Handle errors from API calls
 *
 * @param errors Form errors to add to state
 * @param state State
 */
function handleErrors<T extends ResourceBase>(
    errors: FormErrors,
    state: ResourceState<T>
) {
    state.apiErrors.value = errors;
}

import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "field static-field"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (
                _ctx.config.type === 'static-img' && _ctx.getValue(_ctx.config.value) !== null
            )
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.getValue(_ctx.config.value) || undefined
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true),
        (_ctx.config.type === 'static-text' && _ctx.config.value !== null)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.getValue(_ctx.config.value)), 1))
          : _createCommentVNode("", true),
        (_ctx.config.type === 'static-link')
          ? (_openBlock(), _createElementBlock("a", {
              key: 2,
              class: "btn btn-secondary mx-auto",
              href: _ctx.getValue(_ctx.config.value) ?? '#'
            }, _toDisplayString(_ctx.config.label), 9, _hoisted_4))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
import { ref } from "vue";
import {
    Resource,
    ResourceState,
    resourceStatePrototype,
    useResourceState,
} from "@/domains/app/resource";

export type Failure = Resource & {
    label: string;
};

export type FailureState = ResourceState<Failure>;

/**
 * Create a failure state to get data and its mutators
 * @param id Id of the failure that the state should be related to
 * @returns a failure state with the data and mutators
 */
export function useFailure(id?: number): FailureState {
    const state: FailureState = {
        data: ref(null),
        apiRoute: "failures",
        apiErrors: ref(null),
        save: () => resourceStatePrototype.save(state),
        del: () => resourceStatePrototype.del(state),
        init: () =>
            resourceStatePrototype.init(id, state, {
                id: -1,
                label: "",
            }),
    };

    return useResourceState(state);
}

import { computed, ComputedRef, ref } from "vue";

export type DialogConfig = {
    confirmLabel: string;
    cancelLabel: string;
    confirmClass?: string;
    cancelClass?: string;
};

export type Dialog = {
    uid: number;
    message: string;
    config: DialogConfig;
    confirm: () => void;
    cancel: () => void;
};

const dialogs = ref<Dialog[]>([]);

let currUID = 0;

export function useDialog(
    message: string,
    config: DialogConfig = {
        cancelLabel: "Annuler",
        confirmLabel: "Confirmer",
    }
): Promise<boolean> {
    let resolve: ((b: boolean) => void) | null = null;

    const p = new Promise<boolean>((res) => (resolve = res));

    const d: Dialog = {
        uid: currUID,
        message,
        config,
        confirm: () => {
            removeFromDialogs();
            if (resolve) {
                resolve(true);
            }
        },
        cancel: () => {
            removeFromDialogs();
            if (resolve) {
                resolve(false);
            }
        },
    };

    function removeFromDialogs() {
        dialogs.value = dialogs.value.filter((e) => e.uid !== d.uid);
    }

    currUID++;

    dialogs.value.push(d);
    return p;
}

export function useDialogs(): ComputedRef<Dialog[]> {
    return computed(() => dialogs.value);
}

import { computed, Ref } from "vue";
import {
    FormFields,
    FormParams,
    useObjectEntriesAsFieldValues,
    useRelationAsFieldValue,
} from "@/domains/app/forms";
import { Dog, useDog } from "./dogs";
import {
    createFieldState,
    createNullableFieldState,
} from "@/domains/app/forms";
import { useUser } from "@/domains/auth/user";
import { ROLE_ADMIN } from "../users/users";

export type DogFormState = {
    formArgs: FormParams;
    data: Ref<Dog | null>;
};

/**
 * Create form state descibing how to structure form with its configuration
 *
 * @param id id of the dog in the form
 * @returns a form state
 */
export function useDogForm(id?: number): DogFormState {
    const { data: dog, save, del: remove, apiErrors } = useDog(id);

    const fields = getFields(dog);

    const formArgs: FormParams = {
        title: "Création d'un chien",
        driver: {
            submit: save,
        },
        fields,
        getErrors: () => apiErrors,
    };

    // If Edition mode
    if (id !== undefined && id >= 0) {
        formArgs.title = "Edition d'un chien";
        formArgs.driver.delete = remove;
        const user = useUser().user;
        formArgs.getDisabled = () =>
            computed(
                () =>
                    dog.value !== null &&
                    dog.value.id > 0 &&
                    user.value !== null &&
                    user.value.id !== dog.value.master_id &&
                    !user.value.roles.includes(ROLE_ADMIN)
            );
    }

    const formState: DogFormState = {
        formArgs,
        data: dog,
    };

    return formState;
}

/**
 * Map dog props to form fields
 *
 * @param dog dog state
 * @returns form fields
 */
function getFields(dog: Ref<Dog | null>): FormFields {
    const { user } = useUser();
    const isUserAdmin = computed(
        () => user.value?.roles.includes(ROLE_ADMIN) || false
    );
    return [
        {
            label: "Nom du chien",
            type: "text",
            name: "name",
            getState: () => createFieldState<string>(dog, "name"),
        },
        {
            label: "Agréé",
            type: "boolean",
            name: "certified",
            getState: () => createFieldState<boolean>(dog, "certified"),
            getVisibility: () => isUserAdmin,
            defaultHidden: false,
            defaultVisible: false,
        },
        {
            label: "LOF",
            type: "boolean",
            name: "lof",
            getState: () => createFieldState<boolean>(dog, "lof"),
            getVisibility: () => isUserAdmin,
            defaultHidden: false,
            defaultVisible: false,
        },
        {
            label: "Tatouage",
            type: "text",
            name: "tatoo",
            getState: () => createNullableFieldState<string>(dog, "tatoo"),
        },
        {
            label: "Puce",
            type: "text",
            name: "chip",
            getState: () => createNullableFieldState<string>(dog, "chip"),
        },
        {
            label: "Sexe",
            type: "choice",
            name: "gender",
            getAllowedValues: () =>
                useObjectEntriesAsFieldValues({
                    M: "Mâle",
                    F: "Femelle",
                }),
            getState: () => createFieldState<"M" | "F">(dog, "gender"),
        },
        {
            label: "Race",
            type: "choice",
            name: "breed_id",
            getAllowedValues: () => useRelationAsFieldValue("breeds", "label"),
            getState: () => createFieldState<number>(dog, "breed_id"),
        },
        {
            label: "Maître",
            type: "slot",
            name: "master",
            getState: () => dog,
            getVisibility: () =>
                computed(() => dog.value?.id !== undefined && dog.value.id > 0),
        },
    ];
}

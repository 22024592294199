import { computed, Ref, WritableComputedRef } from "vue";
import { FormFields, FormParams } from "@/domains/app/forms";
import { Position, usePosition } from "./positions";
import { useUser } from "@/domains/auth/user";
import { ROLE_ADMIN } from "../users/users";

export type PositionFormState = {
    formArgs: FormParams;
    data: Ref<Position | null>;
};

/**
 * Create form state descibing how to structure form with its configuration
 *
 * @param id id of the position in the form
 * @returns a form state
 */
export function usePositionForm(id?: number): PositionFormState {
    const { data: position, save, del, apiErrors } = usePosition(id);

    const fields = getFields(position);

    const formArgs: FormParams = {
        title: "Création d'un poste",
        driver: {
            submit: save,
        },
        fields,
        getErrors: () => apiErrors,
    };

    // If Edition mode
    if (id !== undefined && id >= 0) {
        formArgs.title = "Edition d'un poste";
        formArgs.driver.delete = del;
        const user = useUser().user;
        formArgs.getDisabled = () =>
            computed(
                () =>
                    position.value !== null &&
                    position.value.id > 0 &&
                    user.value !== null &&
                    user.value.id !== position.value.user_id &&
                    !user.value.roles.includes(ROLE_ADMIN)
            );
    }

    const formState: PositionFormState = {
        formArgs,
        data: position,
    };

    return formState;
}

/**
 * Map position props to form fields
 *
 * @param position position state
 * @returns form fields
 */
function getFields(position: Ref<Position | null>): FormFields {
    const labelState: WritableComputedRef<string> = computed<string>({
        get: () => (position.value ? position.value.label : ""),
        set: (val: string) => {
            if (position.value) position.value.label = val;
        },
    });

    return [
        {
            label: "Label du poste",
            type: "text",
            name: "label",
            getState: () => labelState,
        },
    ];
}

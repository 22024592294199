import { computed, Ref, WritableComputedRef } from "vue";
import { FormFields, FormParams } from "@/domains/app/forms";
import { HuntType, useHuntType } from "./huntTypes";
import { useUser } from "@/domains/auth/user";
import { ROLE_ADMIN } from "../users/users";

export type HuntTypeFormState = {
    formArgs: FormParams;
    data: Ref<HuntType | null>;
};

/**
 * Create form state descibing how to structure form with its configuration
 *
 * @param id id of the HuntType in the form
 * @returns a form state
 */
export function useHuntTypeForm(id?: number): HuntTypeFormState {
    const { data: huntType, save, del, apiErrors } = useHuntType(id);

    const fields = getFields(huntType);

    const formArgs: FormParams = {
        title: "Création d'une traque",
        driver: {
            submit: save,
        },
        fields,
        getErrors: () => apiErrors,
    };

    // If Edition mode
    if (id !== undefined && id >= 0) {
        formArgs.title = "Edition d'une traque";
        formArgs.driver.delete = del;
        const user = useUser().user;
        formArgs.getDisabled = () =>
            computed(
                () =>
                    huntType.value !== null &&
                    huntType.value.id > 0 &&
                    user.value !== null &&
                    user.value.id !== huntType.value.user_id &&
                    !user.value.roles.includes(ROLE_ADMIN)
            );
    }

    const formState: HuntTypeFormState = {
        formArgs,
        data: huntType,
    };

    return formState;
}

/**
 * Map HuntType props to form fields
 *
 * @param HuntType HuntType state
 * @returns form fields
 */
function getFields(HuntType: Ref<HuntType | null>): FormFields {
    const labelState: WritableComputedRef<string> = computed<string>({
        get: () => (HuntType.value ? HuntType.value.label : ""),
        set: (val: string) => {
            if (HuntType.value) HuntType.value.label = val;
        },
    });

    return [
        {
            label: "Nom de la Traque",
            type: "text",
            name: "label",
            getState: () => labelState,
        },
    ];
}

import { Resource } from "@/domains/app/resource";

export type ListItemConfig = {
    uid: string;
    label: string;
    value?: unknown;
};

export type ListItemDriver<T extends Resource> = {
    getLabel: (r: T) => string;
    loadingKey: string;
};

/**
 * Transforms list of resources to list of configurations for list items
 *
 * @param resources resources to show in list
 * @param getLabel get label to show a given resource
 * @param routePrefix route prefix to find resource related routes
 * @returns
 */
export function makeListItemsFromResources<T extends Resource>(
    resources: T[],
    getLabel: (r: T) => string,
    routePrefix: string
): ListItemConfig[] {
    return resources.map((r) => ({
        uid: "" + r.id,
        label: getLabel(r),
        value: { name: routePrefix + ".edit", params: { id: r.id } },
    }));
}

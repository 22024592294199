import { ref } from "vue";
import {
    Resource,
    ResourceState,
    resourceStatePrototype,
    useResourceState,
} from "@/domains/app/resource";
import { useUser } from "@/domains/auth/user";

export type Requester = Resource & {
    name: string;
    is_member: boolean;
    user_id: number;
};

export type RequesterState = ResourceState<Requester>;

/**
 * Create a requester state to get data and its mutators
 * @param id Id of the requester that the state should be related to
 * @returns a requester state with the data and mutators
 */
export function useRequester(id?: number): RequesterState {
    const state: RequesterState = {
        data: ref(null),
        apiRoute: "requesters",
        apiErrors: ref(null),
        save: () => resourceStatePrototype.save(state),
        del: () => resourceStatePrototype.del(state),
        init: () =>
            resourceStatePrototype.init(id, state, {
                id: -1,
                name: "",
                is_member: false,
                user_id: useUser().user.value?.id || -1,
            }),
    };

    return useResourceState(state);
}

import { computed, Ref, WritableComputedRef } from "vue";
import { FormFields, FormParams } from "@/domains/app/forms";
import { Failure, useFailure } from "./failures";

export type FailureFormState = {
    formArgs: FormParams;
    data: Ref<Failure | null>;
};

/**
 * Create form state descibing how to structure form with its configuration
 *
 * @param id id of the failure in the form
 * @returns a form state
 */
export function useFailureForm(id?: number): FailureFormState {
    const { data: failure, save, del, apiErrors } = useFailure(id);

    const fields = getFields(failure);

    const formArgs: FormParams = {
        title: "Création d'une cause d'échec",
        driver: {
            submit: save,
        },
        fields,
        getErrors: () => apiErrors,
    };

    // If Edition mode
    if (id !== undefined && id >= 0) {
        formArgs.title = "Edition d'une cause d'échec";
        formArgs.driver.delete = del;
    }

    const formState: FailureFormState = {
        formArgs,
        data: failure,
    };

    return formState;
}

/**
 * Map failure props to form fields
 *
 * @param failure failure state
 * @returns form fields
 */
function getFields(failure: Ref<Failure | null>): FormFields {
    const labelState: WritableComputedRef<string> = computed<string>({
        get: () => (failure.value ? failure.value.label : ""),
        set: (val: string) => {
            if (failure.value) failure.value.label = val;
        },
    });

    return [
        {
            label: "Label de la cause d'échec",
            type: "text",
            name: "label",
            getState: () => labelState,
        },
    ];
}


import {
    ResourceBase,
    itemsPerPage,
    PaginatedResourcesState,
    ResourcesState,
    Resource,
} from "@/domains/app/resource";
import {
    computed,
    ComputedRef,
    defineComponent,
    PropType,
    ref,
} from "@vue/runtime-core";
import {
    ListItemConfig,
    ListItemDriver,
    makeListItemsFromResources,
} from "@/domains/search/list";
import { RouteLocationRaw, useRouter } from "vue-router";
import { useUser } from "@/domains/auth/user";
import Search from "@/domains/search/components/Search.vue";
import { useLoading } from "@/domains/app/loading";
import { request } from "@/domains/api/api";
import { useFlashes } from "@/domains/flashes/flashes";

export default defineComponent({
    props: {
        title: String,
        getResourcesState: {
            type: Function as PropType<
                <T extends ResourceBase>() =>
                    | ResourcesState<T>
                    | PaginatedResourcesState<T>
            >,
            default: () => ref([]),
        },
        apiResourceBase: { type: String, required: true },
        makeItemDriver: {
            type: Function as PropType<
                <T extends Resource>() => ListItemDriver<T>
            >,
            required: true,
        },
        newItemRoute: Object as PropType<RouteLocationRaw | null>,
        searchPlaceholder: String as PropType<string | null>,
        headerText: String as PropType<string | null>,
        allowDeleteMany: Boolean as PropType<boolean>,
    },
    setup(props) {
        const resources = props.getResourcesState();
        const driver = props.makeItemDriver();
        const items: ComputedRef<ListItemConfig[]> = computed(() =>
            makeListItemsFromResources(
                resources.data.value,
                driver.getLabel,
                props.apiResourceBase
            )
        );
        const selectedItems = ref<string[]>([]);
        const { canAccess } = useUser();
        const router = useRouter();

        function isPaginatedResources<T extends ResourceBase>(
            resources: ResourcesState<T> | PaginatedResourcesState<T>
        ): resources is PaginatedResourcesState<T> {
            const keys = Object.keys(resources);
            return (
                keys.includes("nextPage") &&
                keys.includes("prevPage") &&
                keys.includes("toPage")
            );
        }

        function selectItem(uid: string) {
            if (selectedItems.value.includes(uid)) return;
            selectedItems.value = [...selectedItems.value, uid];
        }

        function deleteSelection() {
            if (!selectedItems.value.length) return;

            const { setLoading } = useLoading("delete-selection");
            const deleted: string[] = [];
            setLoading(true);
            Promise.all(
                selectedItems.value.map((uid) =>
                    request(props.apiResourceBase + "/" + uid, "delete").then(
                        () => deleted.push(uid)
                    )
                )
            )
                .then(() => {
                    selectedItems.value = selectedItems.value.filter(
                        (v) => !deleted.includes(v)
                    );
                    if (isPaginatedResources(resources)) {
                        resources.refresh();
                    }
                })
                .catch(() => {
                    if (isPaginatedResources(resources)) {
                        resources.refresh();
                    }
                    useFlashes().addFlash(
                        "Impossible de supprimer la selection",
                        "error"
                    );
                })
                .finally(() => {
                    setLoading(false);
                });
        }

        return {
            ...(isPaginatedResources(resources)
                ? {
                      pagesCount: computed(() =>
                          resources.total.value !== null
                              ? Math.ceil(resources.total.value / itemsPerPage)
                              : null
                      ),
                      nextPage: resources.nextPage,
                      prevPage: resources.prevPage,
                      toPage: resources.toPage,
                  }
                : {
                      pagesCount: null,
                      nextPage: undefined,
                      prevPage: undefined,
                      toPage: undefined,
                  }),
            search: resources.search || null,
            items,
            canDelete: (route: unknown) =>
                props.allowDeleteMany &&
                canAccess(router.resolve(route as RouteLocationRaw)),
            canEdit: (route: unknown) =>
                canAccess(router.resolve(route as any)),
            canCreate: props.newItemRoute
                ? canAccess(router.resolve(props.newItemRoute))
                : false,
            loadingKey: driver.loadingKey,
            onItemClicked: (item: ListItemConfig) => {
                router.push(item.value as RouteLocationRaw);
            },
            selectedItems,
            selectItem,
            deleteSelection,
        };
    },
    components: { Search },
});

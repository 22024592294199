export type TranslationMap = {
    [key: string]: null | string;
};

export const validation: TranslationMap = {
    "validation.form.invalid": null,

    // Validation rules translations
    "validation.required": "Veuillez remplir ce champs",
    "validation.available": "Cette valeur n'est pas disponible",
    "validation.unique": "Cette valeur est déjà utilisée",
    "validation.exists": "Veuillez saisir une valeur valide",
    "validation.not-exists":
        "Action impossible car cette entité est utilisée dans une autre",
    "validation.date": "Veuillez saisir une date valide",
    "validation.before": "Vous ne pouvez choisir une date dans le futur",
    "validation.after": "Veuillez saisir une durée",
    "validation.date_format": "Veuillez saisir une date valide",
    "validation.min.numeric": "Veuillez saisir un nombre",
    "validation.regex": "Mauvais format",
    "validation.in": "Vous devez choisir une valeur parmi celles proposées",
    "validation.max.string": "Valeur trop longue",
    "validation.min.string": "Valeur trop courte",
    "validation.not-empty": "Veuillez choisir au moins une valeur",
    "validation.has-negative-state":
        "Vous ne pouvez pas saisir une fiche sur une saison où vous avez déclaré un avis négatif",
    "validation.negative-state.has-sheets":
        "Vous ne pouvez pas déclarer un avis négatif car vous avez déjà saisi des fiches cette saison",
};

export const auth: TranslationMap = {
    "auth.bad-credentials": "Le mot de passe ou l'identifiant n'est pas bon",
    "auth.password.confirmation": "Les mots de passe ne correspondent pas",
    "auth.reset-password.missing-info":
        "Impossible de générer un nouveau token",
    "auth.reset-password.token":
        "Impossible de générer un mot de passse à partir de ce lien, veuillez réessayer de faire une demande de mot de passe oublié",
};

export const globalErrors: TranslationMap = {
    "global.errors.too-many-requests":
        "Vous avez fait trop de requetes, merci d'attendre 1min et de rafraîchir la page",
    "global.errors.disconnected": "Vous avez été déconnecté",
    "global.errors.403": "Vous n'avez pas accès à cette ressource",
    "global.errors.403.account-disabled": "Votre compte a été désactivé",
};

export const customErrors: TranslationMap = {
    "cannot-change-gic-dep":
        "Vous ne pouvez pas changer la visibilié (pays ou utilisateur) d'un GIC",
    "department.administration.user":
        "Vous ne pouvez pas créer au niveau du pays (option désactivée), pour ce faire, contactez un administrateur pour vous donner la main sur votre pays",
};

/**
 * Get full message from message code
 * @param messsageCode Code to identify messsage
 */
export function getMessage(messsageCode: string): string {
    const domains = [validation, auth, globalErrors, customErrors];
    let r;
    domains.every((d) => {
        if (d[messsageCode] !== undefined) {
            r = d[messsageCode];
            return false;
        }
        return true;
    });
    // if no result
    if (r === null) {
        r = "";
    } else if (r === undefined) {
        r = messsageCode;
    }
    return r;
}

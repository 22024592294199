import { computed, Ref, WritableComputedRef } from "vue";
import { FormFields, FormParams } from "@/domains/app/forms";
import { Breed, useBreed } from "./breeds";

export type BreedFormState = {
    formArgs: FormParams;
    data: Ref<Breed | null>;
};

/**
 * Create form state descibing how to structure form with its configuration
 *
 * @param id id of the breed in the form
 * @returns a form state
 */
export function useBreedForm(id?: number): BreedFormState {
    const { data: breed, save, del, apiErrors } = useBreed(id);

    const fields = getFields(breed);

    const formArgs: FormParams = {
        title: "Création d'une race",
        driver: {
            submit: save,
        },
        fields,
        getErrors: () => apiErrors,
    };

    // If Edition mode
    if (id !== undefined && id >= 0) {
        formArgs.title = "Edition d'une race";
        formArgs.driver.delete = del;
    }

    const formState: BreedFormState = {
        formArgs,
        data: breed,
    };

    return formState;
}

/**
 * Map breed props to form fields
 *
 * @param breed breed state
 * @returns form fields
 */
function getFields(breed: Ref<Breed | null>): FormFields {
    const labelState: WritableComputedRef<string> = computed<string>({
        get: () => (breed.value ? breed.value.label : ""),
        set: (val: string) => {
            if (breed.value) breed.value.label = val;
        },
    });

    return [
        {
            label: "Nom de la race",
            type: "text",
            name: "label",
            getState: () => labelState,
        },
    ];
}

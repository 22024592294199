import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "category" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createVNode(_component_router_link, {
      class: "category-link",
      to: { name: _ctx.route }
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.name) + " ", 1),
        _createElementVNode("img", {
          class: "category-icon",
          src: _ctx.img,
          alt: "profil"
        }, null, 8, _hoisted_2)
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}
import { ref } from "vue";
import {
    Resource,
    ResourceState,
    resourceStatePrototype,
    useResourceState,
} from "@/domains/app/resource";

export type Aim = Resource & {
    label: string;
};

export type AimState = ResourceState<Aim>;

/**
 * Create a aim state to get data and its mutators
 * @param id Id of the aim that the state should be related to
 * @returns a aim state with the data and mutators
 */
export function useAim(id?: number): AimState {
    const state: AimState = {
        data: ref(null),
        apiRoute: "aims",
        apiErrors: ref(null),
        save: () => resourceStatePrototype.save(state),
        del: () => resourceStatePrototype.del(state),
        init: () =>
            resourceStatePrototype.init(id, state, {
                id: -1,
                label: "",
            }),
    };

    return useResourceState(state);
}

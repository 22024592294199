import { ref } from "vue";
import {
    Resource,
    ResourceState,
    resourceStatePrototype,
    useResourceState,
} from "@/domains/app/resource";
import { useUser } from "@/domains/auth/user";

export type HuntType = Resource & {
    label: string;
    user_id: number;
};

export type HuntTypeState = ResourceState<HuntType>;

/**
 * Create a HuntType state to get data and its mutators
 * @param id Id of the HuntType that the state should be related to
 * @returns a HuntType state with the data and mutators
 */
export function useHuntType(id?: number): HuntTypeState {
    const state: HuntTypeState = {
        data: ref(null),
        apiRoute: "hunt-types",
        apiErrors: ref(null),
        save: () => resourceStatePrototype.save(state),
        del: () => resourceStatePrototype.del(state),
        init: () =>
            resourceStatePrototype.init(id, state, {
                id: -1,
                label: "",
                user_id: useUser().user.value?.id || -1,
            }),
    };

    return useResourceState(state);
}

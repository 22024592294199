import { ref } from "vue";
import {
    Resource,
    ResourceState,
    resourceStatePrototype,
    useResourceState,
} from "@/domains/app/resource";

export type Weather = Resource & {
    label: string;
};

export type WeatherState = ResourceState<Weather>;

/**
 * Create a weather state to get data and its mutators
 * @param id Id of the weather that the state should be related to
 * @returns a weather state with the data and mutators
 */
export function useWeather(id?: number): WeatherState {
    const state: WeatherState = {
        data: ref(null),
        apiRoute: "weather",
        apiErrors: ref(null),
        save: () => resourceStatePrototype.save(state),
        del: () => resourceStatePrototype.del(state),
        init: () =>
            resourceStatePrototype.init(id, state, {
                id: -1,
                label: "",
            }),
    };

    return useResourceState(state);
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { FieldsErrors, FormErrors } from "@/domains/app/forms";
import { getMessage } from "@/domains/app/translation";
import { useFlashes } from "@/domains/flashes/flashes";
import { Ref } from "vue";
import router from "../app/router/router";

export const API_BASE_URL: string =
    process.env.VUE_APP_API_BASE_URL || "test.domain/api/";
export let API_KEY: string | null = null;

export type ErrorHandler = null | ((err: FormErrors) => void);

export type ApiData<T> = T;

axios.interceptors.request.use(async (reqConfig) => {
    if (!reqConfig.headers) reqConfig.headers = {};
    if (!reqConfig.headers["Content-Type"])
        reqConfig.headers["Content-Type"] = "application/json";
    if (!reqConfig.headers["Accept"])
        reqConfig.headers.Accept = "application/json";
    if (
        !reqConfig.headers.Authorization &&
        API_KEY &&
        reqConfig.url?.startsWith(API_BASE_URL)
    ) {
        reqConfig.headers.Authorization = "Bearer " + API_KEY;
    }
    return reqConfig;
});

/**
 * Make request to api with default headers
 *
 * @param uri relative url
 * @param method method used for request
 * @param showError error handler
 * @param data
 * @param headers
 * @returns api raw data
 */
export function request<T>(
    uri: string,
    method: "post" | "put" | "delete" | "get",
    showError?: ErrorHandler,
    data?: any,
    config?: AxiosRequestConfig
): Promise<T | null> {
    if (!config) config = {};
    if (!config?.headers) config.headers = {};

    config.headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        ...config.headers,
    };

    if (API_KEY) {
        config.headers.Authorization = "Bearer " + API_KEY;
    }

    let getData: string;
    let promise: Promise<AxiosResponse<ApiData<T>>>;
    switch (method) {
        case "get":
            getData =
                (uri.includes("?") ? "&" : "?") +
                (data ? toQueryParams(data).toString() : "");

            promise = axios.get<ApiData<T>>(
                API_BASE_URL + uri + (getData.length > 1 ? getData : ""),
                config
            );
            break;
        case "post":
            promise = axios.post<ApiData<T>>(API_BASE_URL + uri, data, config);
            break;
        case "put":
            promise = axios.put<ApiData<T>>(API_BASE_URL + uri, data, config);
            break;
        case "delete":
            promise = axios.delete<ApiData<T>>(API_BASE_URL + uri, config);
            break;
    }

    return promise
        .then((rs) => {
            if (rs.data !== undefined) {
                return rs.data;
            }
            return null;
        })
        .catch((error) => {
            if (error?.name === "CanceledError") return null;
            const e: FormErrors = handleError(error);
            if (showError) showError(e);
            else addFormFlashError(e);
            throw error;
        });
}

export async function uploadfile(
    file: File,
    progress: Ref<number> | null = null,
    chunkSize = 2000000
): Promise<{ link: string; file: string }> {
    if (progress) progress.value = 0;
    if (file.size <= chunkSize) {
        const data = new FormData();
        data.append("file", file);
        return await axios
            .post<{ link: string; file: string }>(
                API_BASE_URL + "files/upload",
                data,
                {
                    headers: { "Content-Type": "multipart/form-data" },
                }
            )
            .then((rs) => {
                if (progress) progress.value = 1;
                return rs.data;
            });
    }

    const fileRef = await axios
        .post<{ link: string; file: string }>(API_BASE_URL + "files/upload")
        .then((rs) => rs.data.file);

    function uploadChunk(
        start: number
    ): Promise<{ link: string; file: string }> {
        const end = start + chunkSize + 1;
        const chunk = file.slice(start, end);

        let resolve: ((data: string | PromiseLike<string>) => void) | null;
        const fileReader = new FileReader();
        const p = new Promise<string>((res) => (resolve = res));
        fileReader.onloadend = (e) => {
            if (!e.target?.DONE || !resolve) return;
            resolve(e.target.result as string);
        };
        fileReader.onerror = (e) => {
            throw e;
        };
        fileReader.readAsDataURL(chunk);
        return p.then((data) =>
            axios
                .post<{ link: string; file: string }>(
                    API_BASE_URL + "files/assemble",
                    {
                        ref: fileRef,
                        chunk: data,
                    }
                )
                .then((rs) => {
                    if (progress) progress.value = Math.min(end / file.size, 1);
                    if (end < file.size) return uploadChunk(end);
                    return rs.data;
                })
        );
    }

    return uploadChunk(0);
}

export function setKey(key: string | null, expiresAt: number | null) {
    if (key) {
        localStorage.setItem("api-key", JSON.stringify(key));
        localStorage.setItem("api-key-expiration", JSON.stringify(expiresAt));
    } else {
        localStorage.removeItem("api-key-expiration");
        localStorage.removeItem("api-key");
    }
    API_KEY = key;
}

/**
 * Describe an api error
 * @param err error thrown after a request to the api
 * @returns formErrors describing errors for each field of a form
 */
function handleError(err: any): FormErrors {
    if (err.response) {
        // Error thrown by the server
        let message;
        let errors: null | FieldsErrors = null;

        if (err.response.status === 429 || err.response.code === 429) {
            message = getMessage("global.errors.too-many-requests");
        } else if (err.response.status === 429 || err.response.code === 429) {
            message = getMessage("global.errors.too-many-requests");
        } else if (err.response.status === 403 || err.response.code === 403) {
            message = getMessage(
                err.response.data?.message || "global.errors.403"
            );
        } else if (err.response.status === 401 || err.response.code === 401) {
            message = getMessage("global.errors.disconnected");
            router.push({ name: "auth.login" });
        } else if (err.response.status === 422 || err.response.code === 422) {
            // translate messages
            message = getMessage(err?.response?.data?.message);
            if (message === err?.response?.data?.message) {
                message = getMessage("validation.form.invalid");
            }

            // translate field errors
            errors = err.response.data.errors;
            for (const field in errors) {
                for (let i = 0; i < errors[field].length; i++) {
                    errors[field][i] = getMessage(errors[field][i]);
                }
            }
        } else {
            message = "Une erreur est survenue ...";
        }

        return {
            message,
            fields: errors,
        };
    } else if (err.request) {
        // Request sent but no response
        return {
            message: "API not responding",
            fields: null,
        };
    } else {
        // Error happend in the process of creating the request
        return {
            message: "Cannot create request",
            fields: null,
        };
    }
}

function addFormFlashError(errs: FormErrors) {
    const { addFlash } = useFlashes();

    addFlash(errs.message, "error", 10000);
}

type UnparsedQueryParams =
    | string
    | number
    | { [Key: string]: UnparsedQueryParams | UnparsedQueryParams[] };
function toQueryParams(value: UnparsedQueryParams): string {
    if (typeof value === "string" || typeof value === "number") {
        return "" + value;
    }

    const params = new URLSearchParams();
    Object.entries(value).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach((v) => params.append(key + "[]", toQueryParams(v)));
        } else {
            params.append(key, toQueryParams(value));
        }
    });
    return decodeURIComponent(params.toString());
}

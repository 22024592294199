import { ref, Ref } from "vue";
import { createFieldState, FormFields, FormParams } from "@/domains/app/forms";
import { Injury, useInjury } from "./injuries";

export type InjuryFormState = {
    formArgs: FormParams;
    data: Ref<Injury | null>;
};

/**
 * Create form state descibing how to structure form with its configuration
 *
 * @param id id of the injury in the form
 * @returns a form state
 */
export function useInjuryForm(id?: number): InjuryFormState {
    const { data: injury, save, del, apiErrors } = useInjury(id);

    const fields = getFields(injury);

    const formArgs: FormParams = {
        title: "Création d'une blessure",
        driver: {
            submit: save,
        },
        fields,
        getErrors: () => apiErrors,
    };

    // If Edition mode
    if (id !== undefined && id >= 0) {
        formArgs.title = "Edition d'une blessure";
        formArgs.driver.delete = del;
    }

    const formState: InjuryFormState = {
        formArgs,
        data: injury,
    };

    return formState;
}

/**
 * Map injury props to form fields
 *
 * @param injury injury state
 * @returns form fields
 */
function getFields(injury: Ref<Injury | null>): FormFields {
    return [
        {
            label: "Nom de la blessure",
            type: "text",
            name: "label",
            getState: () => createFieldState<string>(injury, "label"),
        },
        {
            label: "Disponible dans la saisie de fiches",
            type: "boolean",
            name: "available",
            getState: () => createFieldState<string>(injury, "available"),
        },
        {
            label: "Zones",
            type: "choice-multiple",
            name: "label",
            getAllowedValues: () =>
                ref(
                    Array(24)
                        .fill(0)
                        .map((_, i) => ({ key: i + 1, value: "" + (i + 1) }))
                ),
            getState: () => createFieldState<number>(injury, "zones"),
        },
    ];
}

import { reactive } from "vue";
import { request } from "@/domains/api/api";
import { ResourceBase } from "@/domains/app/resource";
import { sheetStatisticsColumns } from "./config";
import { SheetStatisticsColumns } from "./statistics";

type RelationsWithLabels = Record<
    keyof SheetStatisticsColumns,
    Record<string, ResourceBase & { label: string }>
>;

type ResourceWithLabelCol<T extends string> = ResourceBase & Record<T, string>;

const relations = reactive<RelationsWithLabels>({});

let relationsReady = false;

export function useRelations(): typeof relations {
    if (relationsReady) return relations;
    relationsReady = true;

    for (const [col, config] of Object.entries(sheetStatisticsColumns)) {
        if (config?.type !== "relation") continue;
        relations[col] = {};
        request<ResourceWithLabelCol<typeof config["relationCol"]>[]>(
            config.relation,
            "get"
        ).then((data) => {
            data = data as
                | ResourceWithLabelCol<typeof config["relationCol"]>[]
                | null;
            if (data) {
                relations[col] = data.reduce<
                    RelationsWithLabels[number | string]
                >((acc, resource) => {
                    acc["" + resource.id] = {
                        id: resource.id,
                        label: resource[config.relationCol],
                    };
                    return acc;
                }, {});
            }
        });
    }

    return relations;
}

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "categories-index" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CategoryTile = _resolveComponent("CategoryTile")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        (_ctx.show(category.route))
          ? (_openBlock(), _createBlock(_component_CategoryTile, {
              key: 0,
              name: category.name,
              img: category.img,
              route: category.route
            }, null, 8, ["name", "img", "route"]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}
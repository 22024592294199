import { computed, Ref, WritableComputedRef } from "vue";
import { FormFields, FormParams } from "@/domains/app/forms";
import { Ammunition, useAmmunition } from "./ammunitions";

export type AmmunitionFormState = {
    formArgs: FormParams;
    data: Ref<Ammunition | null>;
};

/**
 * Create form state descibing how to structure form with its configuration
 *
 * @param id id of the ammunition in the form
 * @returns a form state
 */
export function useAmmunitionForm(id?: number): AmmunitionFormState {
    const { data: ammunition, save, del, apiErrors } = useAmmunition(id);

    const fields = getFields(ammunition);

    const formArgs: FormParams = {
        title: "Création d'une munition",
        driver: {
            submit: save,
        },
        fields,
        getErrors: () => apiErrors,
    };

    // If Edition mode
    if (id !== undefined && id >= 0) {
        formArgs.title = "Edition d'une munition";
        formArgs.driver.delete = del;
    }

    const formState: AmmunitionFormState = {
        formArgs,
        data: ammunition,
    };

    return formState;
}

/**
 * Map ammunition props to form fields
 *
 * @param ammunition ammunition state
 * @returns form fields
 */
function getFields(ammunition: Ref<Ammunition | null>): FormFields {
    const labelState: WritableComputedRef<string> = computed<string>({
        get: () => (ammunition.value ? ammunition.value.label : ""),
        set: (val: string) => {
            if (ammunition.value) ammunition.value.label = val;
        },
    });

    return [
        {
            label: "Nom de la munition",
            type: "text",
            name: "label",
            getState: () => labelState,
        },
    ];
}

import { ComputedRef } from "vue";
import {
    PaginatedResourcesState,
    Resource,
    ResourcesState,
    usePaginatedResourcesState,
    useResourcesState,
} from "../app/resource";
import { Aim } from "../cruds/aims/aims";
import { Ammunition } from "../cruds/ammunitions/ammunitions";
import { Calibre } from "../cruds/calibres/calibres";
import { Department } from "../cruds/departments/departments";
import { Dog } from "../cruds/dogs/dogs";
import { Failure } from "../cruds/failures/failures";
import { Game } from "../cruds/games/games";
import { Gic } from "../cruds/gic/gic";
import { Hunt } from "../cruds/hunts/hunts";
import { HuntType } from "../cruds/huntTypes/huntTypes";
import { Injury } from "../cruds/injuries/injuries";
import { Location } from "../cruds/locations/locations";
import { Pace } from "../cruds/paces/paces";
import { Position } from "../cruds/positions/positions";
import { Pursuit } from "../cruds/pursuits/pursuits";
import { Requester } from "../cruds/requesters/requesters";
import { Result } from "../cruds/results/results";
import { Shot } from "../cruds/shots/shots";
import { User } from "../cruds/users/users";
import { Weapon } from "../cruds/weapons/weapons";
import { WeaponType } from "../cruds/weaponTypes/weaponTypes";
import { Weather } from "../cruds/weather/weather";
import { SheetFilterColumns } from "../statistics/statistics";

export type RelationsConfigType = {
    [key in keyof SheetFilterColumns]?: RelationConfigType;
};

export type RelationConfigType = {
    getState: <T extends Resource>(
        p: QueryParams
    ) => ResourcesState<T> | PaginatedResourcesState<T>;
    getLabel: <T extends Resource>(value: T) => string;
    loadingKey: string;
};

export type QueryParams = ComputedRef<Record<string, string>> | undefined;

export const relations: RelationsConfigType = {
    dog_id: {
        getState: (queryParams: QueryParams = undefined) =>
            usePaginatedResourcesState("dogs", true, queryParams),
        getLabel: (v) => (v as unknown as Dog).name,
        loadingKey: "dogs.loading.list",
    },
    user_id: {
        getState: (queryParams: QueryParams = undefined) =>
            usePaginatedResourcesState("users", true, queryParams),
        getLabel: (v) =>
            (v as unknown as User).first_name +
            " " +
            (v as unknown as User).last_name,
        loadingKey: "users.loading.list",
    },
    result_id: {
        getState: (queryParams: QueryParams = undefined) =>
            useResourcesState("results", false, queryParams),
        getLabel: (v) => (v as unknown as Result).label,
        loadingKey: "results.loading.list",
    },
    department_id: {
        getState: (queryParams: QueryParams = undefined) =>
            useResourcesState("departments", false, queryParams),
        getLabel: (v) => (v as unknown as Department).label,
        loadingKey: "departments.loading.list",
    },
    location_id: {
        getState: (queryParams: QueryParams = undefined) =>
            usePaginatedResourcesState("locations", true, queryParams),
        getLabel: (v) => (v as unknown as Location).label,
        loadingKey: "locations.loading.list",
    },
    gic_id: {
        getState: (queryParams: QueryParams = undefined) =>
            usePaginatedResourcesState("gics", true, queryParams),
        getLabel: (v) => (v as unknown as Gic).label,
        loadingKey: "gics.loading.list",
    },
    requester_id: {
        getState: (queryParams: QueryParams = undefined) =>
            usePaginatedResourcesState("requesters", true, queryParams),
        getLabel: (v) => (v as unknown as Requester).name,
        loadingKey: "requesters.loading.list",
    },
    game_id: {
        getState: (queryParams: QueryParams = undefined) =>
            useResourcesState("games", false, queryParams),
        getLabel: (v) => (v as unknown as Game).label,
        loadingKey: "games.loading.list",
    },
    weapon_id: {
        getState: (queryParams: QueryParams = undefined) =>
            usePaginatedResourcesState("weapons", true, queryParams),
        getLabel: (v) => (v as unknown as Weapon).label,
        loadingKey: "weapons.loading.list",
    },
    weapon_type_id: {
        getState: (queryParams: QueryParams = undefined) =>
            useResourcesState("weapon-types", false, queryParams),
        getLabel: (v) => (v as unknown as WeaponType).label,
        loadingKey: "weapon-types.loading.list",
    },
    aim_id: {
        getState: (queryParams: QueryParams = undefined) =>
            useResourcesState("aims", false, queryParams),
        getLabel: (v) => (v as unknown as Aim).label,
        loadingKey: "aims.loading.list",
    },
    shot_id: {
        getState: (queryParams: QueryParams = undefined) =>
            useResourcesState("shots", false, queryParams),
        getLabel: (v) => (v as unknown as Shot).label,
        loadingKey: "shots.loading.list",
    },
    hunt_type_id: {
        getState: (queryParams: QueryParams = undefined) =>
            usePaginatedResourcesState("hunt-types", true, queryParams),
        getLabel: (v) => (v as unknown as HuntType).label,
        loadingKey: "hunt-types.loading.list",
    },
    hunt_id: {
        getState: (queryParams: QueryParams = undefined) =>
            useResourcesState("hunts", false, queryParams),
        getLabel: (v) => (v as unknown as Hunt).label,
        loadingKey: "hunts.loading.list",
    },
    pursuit_id: {
        getState: (queryParams: QueryParams = undefined) =>
            useResourcesState("pursuits", false, queryParams),
        getLabel: (v) => (v as unknown as Pursuit).label,
        loadingKey: "pursuits.loading.list",
    },
    ammunition_id: {
        getState: (queryParams: QueryParams = undefined) =>
            useResourcesState("ammunitions", false, queryParams),
        getLabel: (v) => (v as unknown as Ammunition).label,
        loadingKey: "ammunitions.loading.list",
    },
    calibre_id: {
        getState: (queryParams: QueryParams = undefined) =>
            useResourcesState("calibres", false, queryParams),
        getLabel: (v) => (v as unknown as Calibre).label,
        loadingKey: "calibres.loading.list",
    },
    pace_id: {
        getState: (queryParams: QueryParams = undefined) =>
            useResourcesState("paces", false, queryParams),
        getLabel: (v) => (v as unknown as Pace).label,
        loadingKey: "paces.loading.list",
    },
    failures: {
        getState: (queryParams: QueryParams = undefined) =>
            useResourcesState("failures", false, queryParams),
        getLabel: (v) => (v as unknown as Failure).label,
        loadingKey: "failures.loading.list",
    },
    injuries: {
        getState: (queryParams: QueryParams = undefined) =>
            useResourcesState("injuries", false, queryParams),
        getLabel: (v) => (v as unknown as Injury).label,
        loadingKey: "injuries.loading.list",
    },
    weather_id: {
        getState: (queryParams: QueryParams = undefined) =>
            useResourcesState("weather", false, queryParams),
        getLabel: (v) => (v as unknown as Weather).label,
        loadingKey: "weather.loading.list",
    },
    position_id: {
        getState: (queryParams: QueryParams = undefined) =>
            usePaginatedResourcesState("positions", true, queryParams),
        getLabel: (v) => (v as unknown as Position).label,
        loadingKey: "positions.loading.list",
    },
};

import { Ref, ref } from "vue";
import { ErrorHandler, request } from "@/domains/api/api";
import {
    Resource,
    ResourceState,
    resourceStatePrototype,
    useResourceState,
} from "@/domains/app/resource";
import { useUser } from "@/domains/auth/user";
import { Game } from "../games/games";

export type Sheet = Resource & {
    // Required
    ref?: number;
    department_id: number;
    user_id: number;
    dog_id: number;
    result_id: number;
    hunt_id: number;
    shot_id: number;
    game_id: number;
    pursuit_id: number | null;
    season_id: number;
    date: string;
    gender: "M" | "F" | "U";
    weight: number | null;
    age: "1INF" | "1SUP" | "U";
    path_age: number;
    leash_length: number;
    duration: string;
    pursuit_length: number | null;
    pursuit_duration: string | null;
    bracelet_num: number | null;
    disturbed: boolean | null;
    injuries: {
        priority: number;
        zone_in: null | number;
        zone_out: null | number;
        injury_id: number;
    }[];
    failures: {
        priority: number;
        failure_id: number;
    }[];

    // Complementary
    ammunition_id: number | null;
    hunt_type_id: number | null;
    shot_distance: number | null;
    position_id: number | null;
    requester_id: number | null;
    gic_id: number | null;
    location_id: number | null;
    weather_id: number | null;
    weapon_id: number | null;
    aim_id: number | null;
    calibre_id: number | null;
    pace_id: number | null;
    gps: string | null;
    description: string | null;
    vehicule_km: number | null;
    paid_trip: boolean | null;

    paid_trip_observer: boolean | null;
    paid_trip_observer_phone: boolean | null;
    paid_trip_city: boolean | null;
    paid_trip_postal_code: boolean | null;

    meta?: {
        user?: { name: string };
    };

    game?: Game | null;
};

export type SheetState = ResourceState<Sheet>;

/**
 * Create a sheet state to get data and its mutators
 * @param id Id of the sheet that the state should be related to
 * @returns a sheet state with the data and mutators
 */
export function useSheet(from?: number | Ref<Sheet>): SheetState {
    const id = typeof from === "object" ? from.value.id : from;

    const state: SheetState = {
        data: typeof from === "object" ? from : ref(null),
        apiRoute: "sheets",
        apiErrors: ref(null),
        save: () => {
            if (state.data.value?.duration.length === 8)
                state.data.value.duration = state.data.value.duration.substring(
                    0,
                    5
                );
            if (state.data.value?.pursuit_duration?.length === 8)
                state.data.value.pursuit_duration =
                    state.data.value.pursuit_duration.substring(0, 5);
            return resourceStatePrototype.save(state);
        },
        del: () => resourceStatePrototype.del(state),
        init: () =>
            state.data.value?.id === undefined || state.data.value?.id <= 0
                ? resourceStatePrototype.init(id, state, {
                      id: -1,
                      department_id: -1,
                      user_id: useUser().user.value?.id || -1,
                      dog_id: -1,
                      result_id: -1,
                      shot_id: -1,
                      requester_id: null,
                      location_id: null,
                      weather_id: null,
                      game_id: -1,
                      weapon_id: null,
                      aim_id: null,
                      hunt_id: -1,
                      gic_id: null,
                      pursuit_id: null,
                      position_id: null,
                      hunt_type_id: null,
                      calibre_id: null,
                      pace_id: null,
                      ammunition_id: null,
                      season_id: -1,
                      failures: [],
                      injuries: [],
                      date: "",
                      gender: "U",
                      weight: null,
                      age: "U",
                      path_age: 0,
                      leash_length: 0,
                      duration: "00:00",
                      pursuit_duration: null,
                      pursuit_length: null,
                      vehicule_km: null,
                      paid_trip: null,
                      bracelet_num: null,
                      description: null,
                      shot_distance: null,
                      disturbed: false,
                      gps: null,

                      paid_trip_city: null,
                      paid_trip_observer: null,
                      paid_trip_observer_phone: null,
                      paid_trip_postal_code: null,
                  })
                : Promise.resolve(),
    };

    return useResourceState(state);
}

type UserSheetsRecap = {
    negativeState: boolean;
    total: number;
    games: { id: number; count: number }[];
    "recent-sheets": Sheet[];
};
export function getSheetsRecap(
    showError: ErrorHandler
): Promise<UserSheetsRecap> {
    return request<UserSheetsRecap>("sheets/recap", "get", showError).then(
        (data) =>
            data === null
                ? {
                      games: [],
                      total: 0,
                      "recent-sheets": [],
                      negativeState: false,
                  }
                : data
    );
}

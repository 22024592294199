import { ref } from "vue";
import {
    Resource,
    ResourceState,
    resourceStatePrototype,
    useResourceState,
} from "@/domains/app/resource";

export type Result = Resource & {
    label: string;
};

/** RESULT IDS */
export const POSITIVE_CONTROL_IDS = [2, 3, 4];
export const NEGATIVE_CONTROL_IDS = [1];
export const FAILURE_IDS = [5, 6, 7, 8, 9, 10];
export const SUCCESSFUL_IDS = [11, 12, 13, 14];

export const POSITIVE_FOR_RATE_IDS = [2, 11, 12, 13, 14];

export type ResultState = ResourceState<Result>;

/**
 * Create a result state to get data and its mutators
 * @param id Id of the result that the state should be related to
 * @returns a result state with the data and mutators
 */
export function useResult(id?: number): ResultState {
    const state: ResultState = {
        data: ref(null),
        apiRoute: "results",
        apiErrors: ref(null),
        save: () => resourceStatePrototype.save(state),
        del: () => resourceStatePrototype.del(state),
        init: () =>
            resourceStatePrototype.init(id, state, {
                id: -1,
                label: "",
            }),
    };

    return useResourceState(state);
}

import { Ref, computed } from "vue";
import {
    createFieldState,
    createNullableFieldState,
    FormFields,
    FormParams,
    useRelationAsFieldValue,
} from "@/domains/app/forms";
import { User, useUser } from "./users";
import { useUser as useLoggedUser } from "../../auth/user";

export type UserFormState = {
    formArgs: FormParams;
    data: Ref<User | null>;
};

/**
 * Create form state descibing how to structure form with its configuration
 *
 * @param id id of the user in the form
 * @returns a form state
 */
export function useProfileForm(): UserFormState {
    const id = useLoggedUser().user.value?.id;
    if (!id) throw "Cannot find user";
    const { data: user, save, apiErrors } = useUser(id);

    const fields = getFields(user);

    const formArgs: FormParams = {
        title: "Création d'un utilisateur",
        driver: {
            submit: save,
        },
        fields,
        getErrors: () => apiErrors,
    };

    const formState: UserFormState = {
        formArgs,
        data: user,
    };

    return formState;
}

/**
 * Map user props to form fields
 *
 * @param user user state
 * @returns form fields
 */
function getFields(user: Ref<User | null>): FormFields {
    const fields: FormFields = [
        {
            type: "static-text",
            value: computed(
                () => "Numéro d'adhérent: " + user.value?.member_num
            ),
        },
        {
            label: "Prénom",
            type: "text",
            name: "first_name",
            getState: () => createFieldState<string>(user, "first_name"),
        },
        {
            label: "Nom",
            type: "text",
            name: "last_name",
            getState: () => createFieldState<string>(user, "last_name"),
        },
        {
            label: "Email",
            type: "text",
            name: "email",
            getState: () => createNullableFieldState<string>(user, "email"),
        },
        {
            label: "Departement",
            type: "choice",
            name: "department_id",
            getAllowedValues: () =>
                useRelationAsFieldValue("departments", "label"),
            getState: () => createFieldState<number>(user, "department_id"),
        },
    ];

    return fields;
}

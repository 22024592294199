
import { defineComponent, PropType } from "@vue/runtime-core";
import { FileFieldConfig } from "../forms";
import FileUploader from "./FileUploader.vue";

export default defineComponent({
    components: { FileUploader },
    props: {
        config: { type: Object as PropType<FileFieldConfig>, required: true },
        errors: Object as PropType<null | string[]>,
    },
    setup(props) {
        return {
            state: props.config.getState(),
        };
    },
});


import { uploadfile } from "@/domains/api/api";
import { Ref, ref } from "@vue/reactivity";
import { defineComponent } from "@vue/runtime-core";
import { UploadedFile } from "../forms";
import { computed, PropType } from "vue";

export default defineComponent({
    props: {
        file: Object as PropType<UploadedFile | null>,
        title: {
            type: String,
            default: "Uploadez votre image",
        },
        accept: String,
    },

    setup(props, { emit }) {
        const fileInput = ref<HTMLInputElement | null>(null);
        const error: Ref<string> = ref("");
        const uploadProgress = ref(0);
        const isUploading = ref(false);

        const isImg = ref(checkImg(props.file?.filename));

        function checkImg(filename?: string | null): boolean {
            if (!filename) return false;
            const tmp = filename.split(".");
            if (tmp.length <= 1) return false;
            return ["jpg", "png", "webp", "svg", "jpeg"].includes(
                tmp[tmp.length - 1]
            );
        }

        async function uploadFile() {
            error.value = "";
            uploadProgress.value = 0;
            isUploading.value = true;
            if (!fileInput.value) return;
            let files = fileInput.value.files;
            if (!files || !files.length) return;
            const rs = await uploadfile(files[0], uploadProgress).catch(() => {
                error.value = "Impossible d'uploader le fichier sur le serveur";
                return null;
            });
            uploadProgress.value = 0;
            isUploading.value = false;
            if (!rs) return;
            isImg.value = checkImg(files[0].name);
            emit("update:file", {
                filename: files[0].name,
                link: rs.link,
                filePath: rs.file,
            });
        }

        return {
            uploadFile,
            fileInput,
            openFileUploadModal: () => {
                if (fileInput.value) {
                    fileInput.value.click();
                }
            },
            error,
            isImg,
            progress: computed(
                () => Math.round(uploadProgress.value * 100) + "%"
            ),
            isUploading,
        };
    },
});

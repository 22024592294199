
import { Ref, ref, ComputedRef, computed } from "@vue/reactivity";
import { defineComponent } from "@vue/runtime-core";
import Form from "@/domains/app/components/Form.vue";
import { FormErrors, FormParams } from "@/domains/app/forms";
import { getMessage } from "@/domains/app/translation";
import { resetPassword } from "../user";
import { useRoute, useRouter } from "vue-router";
import { useFlashes } from "@/domains/flashes/flashes";

type Requirement = {
    valid: ComputedRef<boolean>;
    label: string;
};

export default defineComponent({
    components: {
        Form,
    },
    setup() {
        const specialCharRegex = new RegExp(/.*[^a-zA-Z0-9\s].*/g);
        const capitalizedRegex = new RegExp(/.*[A-Z].*/g);
        const requirements: Requirement[] = [
            {
                label: "6 caractères minimum",
                valid: computed(() => {
                    return password.value?.length >= 6;
                }),
            },
            {
                label: "1 caractère spécial",
                valid: computed(() =>
                    password.value?.match(specialCharRegex) ? true : false
                ),
            },
            {
                label: "1 majuscule",
                valid: computed(() =>
                    password.value?.match(capitalizedRegex) ? true : false
                ),
            },
            {
                label: "Mots de passe identiques",
                valid: computed(
                    () =>
                        password.value !== "" &&
                        password.value === passwordConfirmation.value
                ),
            },
        ];
        const password = ref("");
        const passwordConfirmation = ref("");

        const formErrors: Ref<FormErrors | null> = ref(null);

        function showErr(err: FormErrors) {
            const errKey = err.fields?.form?.length
                ? err.fields.form[0]
                : err.message;
            if (errKey === "auth.reset-password.token") {
                formErrors.value = {
                    message:
                        "Une erreur s'est produite, essayez de faire mot de passe oublié sur la page de connexion avec votre email.",
                    fields: {},
                };
            } else {
                formErrors.value = {
                    message: getMessage(
                        err.fields?.form?.length
                            ? err.fields.form[0]
                            : err.message
                    ),
                    fields: err.fields,
                };
            }
        }

        const route = useRoute();
        const router = useRouter();

        const token: string =
            (Array.isArray(route.query.token)
                ? route.query.token[0]
                : route.query.token) || "";

        const email: string =
            (Array.isArray(route.query.email)
                ? route.query.email[0]
                : route.query.email) || "";

        if (!email || !token) {
            useFlashes().addFlash(
                getMessage("auth.reset-password.missing-info"),
                "error"
            );
            router.push({ name: "auth.login" });
            return {};
        }

        const formParams: FormParams = {
            driver: {
                submit: () => {
                    formErrors.value = null;
                    if (password.value !== passwordConfirmation.value) {
                        showErr({
                            message: "auth.password.confirmation",
                            fields: {},
                        });
                        return Promise.resolve();
                    }
                    return resetPassword(
                        password.value,
                        token,
                        email,
                        showErr
                    ).then(() => {
                        router.push({ name: "index" });
                    });
                },
            },
            fields: [
                {
                    label: "Mot de passe",
                    type: "text:hidden",
                    getState: () => password,
                    name: "password",
                },
                {
                    label: "Confirmation du mot de passe",
                    type: "text:hidden",
                    getState: () => passwordConfirmation,
                    name: "passwordConfirmation",
                },
                {
                    type: "slot",
                    label: "Indications",
                    name: "indications",
                    getState: () => ref(null),
                },
            ],
            title: "Nouveau mot de passe",
            getErrors: () => formErrors,
        };

        return {
            formParams,
            requirements,
            isFormValid: computed(() =>
                requirements.every((e) => e.valid.value)
            ),
        };
    },
});

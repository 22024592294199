
import { useFlashes } from "@/domains/flashes/flashes";
import {
    computed,
    defineComponent,
    PropType,
    ref,
    Ref,
    ComputedRef,
} from "@vue/runtime-core";
import { useDialog } from "../dialogs/dialogs";
import { FormFields, FormDriver, FormErrors } from "../forms";
import { useLoading } from "../loading";
import Field from "./Field.vue";
import FileField from "./FileField.vue";
import StaticField from "./StaticField.vue";

export default defineComponent({
    components: {
        Field,
        FileField,
        StaticField,
    },
    props: {
        // Fields to put in form
        fields: Object as PropType<FormFields>,

        // Complementary field hidden by default
        complementaryFields: Object as PropType<FormFields>,

        // Driver to tell the form how to behave
        driver: Object as PropType<FormDriver>,

        // Object containing errors to show
        getErrors: Function as PropType<() => Ref<FormErrors | null>>,

        // Object containing errors to show
        getDisabled: Function as PropType<
            () => ComputedRef<boolean> | undefined
        >,

        // Form title
        title: String,

        loadingKey: String,

        isResourceForm: Boolean,

        showSubmitedFlash: { type: Boolean, default: true },

        canSubmit: { type: Boolean, default: true },
    },
    emits: {
        submit: Function,
    },
    setup(props, { emit }) {
        const showComplementaryFields = ref(false);

        // Function to get errors of a specific field from errors in props
        const getFieldErrors = (fieldName: string) => {
            const fieldsErrors = errors.value?.fields;
            if (fieldsErrors) {
                return fieldsErrors[fieldName];
            }
            return null;
        };

        const errors: Ref<FormErrors | null> = props.getErrors
            ? props.getErrors()
            : ref({ message: "", fields: null });

        const updateResourceLoading = useLoading("resource-update").loading;
        const createResourceLoading = useLoading("resource-create").loading;
        const deleteResourceLoading = useLoading("resource-delete").loading;
        const loading = props.loadingKey
            ? useLoading(props.loadingKey).loading
            : null;

        const { addFlash } = useFlashes();

        return {
            disabled:
                props.getDisabled === undefined ? false : props.getDisabled(),
            getFieldErrors,
            errors,
            showComplementaryFields,
            onToggleComplementaryFieldsVisibility: () =>
                (showComplementaryFields.value =
                    !showComplementaryFields.value),
            submit: () => {
                if (!props.driver?.submit) return;
                errors.value = null;
                props.driver
                    ?.submit()
                    .then(() => {
                        if (props.showSubmitedFlash)
                            addFlash("Action approuvée", "success", 2000);
                        emit("submit");
                    })
                    .catch(() =>
                        document
                            .querySelector(".errors *")
                            ?.scrollIntoView({ behavior: "smooth" })
                    );
            },
            driverDelete: props.driver?.delete
                ? () => {
                      errors.value = null;
                      useDialog("Etes vous sur de vouloir supprimer ?", {
                          confirmLabel: "Supprimer",
                          cancelLabel: "Annuler",
                          confirmClass: "btn btn-primary danger",
                          cancelClass: "btn btn-light",
                      }).then((confirmed) => {
                          if (confirmed && props.driver?.delete) {
                              props.driver
                                  .delete()
                                  .then(() =>
                                      addFlash("Supprimé", "success", 2000)
                                  )
                                  .catch(() =>
                                      document
                                          .querySelector(".errors *")
                                          ?.scrollIntoView({
                                              behavior: "smooth",
                                          })
                                  );
                          }
                      });
                  }
                : undefined,
            submitLoading:
                props.isResourceForm || props.loadingKey
                    ? computed(
                          () =>
                              updateResourceLoading.value ||
                              createResourceLoading.value ||
                              deleteResourceLoading.value ||
                              loading?.value
                      )
                    : false,
        };
    },
});

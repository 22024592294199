import { computed, ComputedRef, ref } from "vue";

const TITLE_BASE = "Geschasse - ";
const TITLE_DEFAULT = "Geschasse";
const titleRef = ref<string>(TITLE_DEFAULT);

type MetaTitleState = {
    title: ComputedRef<string>;
    setTitle: (title: string | null) => void;
};

export function useMetaTitle(): MetaTitleState {
    return {
        setTitle: (title: string | null) => {
            titleRef.value = title ? TITLE_BASE + title : TITLE_DEFAULT;
        },
        title: computed(() => titleRef.value),
    };
}

import { computed, Ref, WritableComputedRef } from "vue";
import { FormFields, FormParams } from "@/domains/app/forms";
import { Hunt, useHunt } from "./hunts";

export type HuntFormState = {
    formArgs: FormParams;
    data: Ref<Hunt | null>;
};

/**
 * Create form state descibing how to structure form with its configuration
 *
 * @param id id of the hunt in the form
 * @returns a form state
 */
export function useHuntForm(id?: number): HuntFormState {
    const { data: hunt, save, del, apiErrors } = useHunt(id);

    const fields = getFields(hunt);

    const formArgs: FormParams = {
        title: "Création d'un type de chasse",
        driver: {
            submit: save,
        },
        fields,
        getErrors: () => apiErrors,
    };

    // If Edition mode
    if (id !== undefined && id >= 0) {
        formArgs.title = "Edition d'un type de chasse";
        formArgs.driver.delete = del;
    }

    const formState: HuntFormState = {
        formArgs,
        data: hunt,
    };

    return formState;
}

/**
 * Map hunt props to form fields
 *
 * @param hunt hunt state
 * @returns form fields
 */
function getFields(hunt: Ref<Hunt | null>): FormFields {
    const labelState: WritableComputedRef<string> = computed<string>({
        get: () => (hunt.value ? hunt.value.label : ""),
        set: (val: string) => {
            if (hunt.value) hunt.value.label = val;
        },
    });

    return [
        {
            label: "Nom de la chasse",
            type: "text",
            name: "label",
            getState: () => labelState,
        },
    ];
}

import { Ref, computed, ref } from "vue";
import {
    createFieldState,
    createNullableFieldState,
    FormFields,
    FormParams,
    useRelationAsFieldValue,
} from "@/domains/app/forms";
import { ROLE_ADMIN, ROLE_DELEGATE, ROLE_USER, User, useUser } from "./users";

export type UserFormState = {
    formArgs: FormParams;
    data: Ref<User | null>;
};

/**
 * Create form state descibing how to structure form with its configuration
 *
 * @param id id of the user in the form
 * @returns a form state
 */
export function useUserForm(id?: number): UserFormState {
    const { data: user, save, del: remove, apiErrors } = useUser(id);

    const fields = getFields(user);

    const formArgs: FormParams = {
        title: "Création d'un utilisateur",
        driver: {
            submit: save,
        },
        fields,
        getErrors: () => apiErrors,
    };

    // If Edition mode
    if (id !== undefined && id >= 0) {
        formArgs.title = "Edition d'un utilisateur";
        formArgs.driver.delete = remove;
    }

    const formState: UserFormState = {
        formArgs,
        data: user,
    };

    return formState;
}

/**
 * Map user props to form fields
 *
 * @param user user state
 * @returns form fields
 */
function getFields(user: Ref<User | null>): FormFields {
    const all_roles = [ROLE_USER, ROLE_DELEGATE, ROLE_ADMIN];
    const fields: FormFields = [
        {
            label: "Numéro d'adhérent",
            type: "text",
            name: "member_num",
            getState: () => createFieldState<string>(user, "member_num"),
        },
        {
            label: "Prénom",
            type: "text",
            name: "first_name",
            getState: () => createFieldState<string>(user, "first_name"),
        },
        {
            label: "Nom",
            type: "text",
            name: "last_name",
            getState: () => createFieldState<string>(user, "last_name"),
        },
        {
            type: "static-text",
            value: ref(
                "Un email sera envoyé pour que la personne saisisse son mot de passe"
            ),
            getVisibility: () =>
                computed(
                    () =>
                        user.value !== null &&
                        user.value.id < 0 &&
                        !user.value.disabled
                ),
        },
        {
            label: "Email",
            type: "text",
            name: "email",
            getState: () => createNullableFieldState<string>(user, "email"),
        },
        {
            label: "Pays",
            type: "choice",
            name: "department_id",
            getAllowedValues: () =>
                useRelationAsFieldValue("departments", "label"),
            getState: () => createFieldState<number>(user, "department_id"),
        },
        {
            label: "Compte désactivé",
            type: "boolean",
            name: "disabled",
            getState: () => createFieldState<boolean>(user, "disabled"),
        },
        {
            label: "Roles",
            type: "choice-multiple",
            name: "roles",
            getAllowedValues: () =>
                ref([
                    { key: 0, value: "Utilisateur" },
                    { key: 1, value: "Délégué" },
                    { key: 2, value: "Administrateur" },
                ]),
            getState: () =>
                computed<number[]>({
                    // Translate Roles to role indexes
                    get: () => {
                        const r: number[] = [];
                        if (!user.value) return [];
                        for (let i = 0; i < all_roles.length; i++) {
                            if (user.value.roles.includes(all_roles[i]))
                                r.push(i);
                        }
                        return r;
                    },
                    // Translate role indexes to roles with at least user role
                    set: (roles: number[]) => {
                        if (!roles.includes(0)) roles.push(0);
                        if (user.value)
                            user.value.roles = roles.map(
                                (key) => all_roles[key]
                            );
                    },
                }),
        },
    ];

    if (process.env.VUE_APP_ENABLE_PAID_TRIPS === "true") {
        fields.push({
            label: "Peut bénéficier de la réduction kilométrique",
            type: "boolean",
            getState: () => createFieldState<boolean>(user, "allow_paid_trips"),
            name: "allow_paid_trips",
        });
    }

    return fields;
}

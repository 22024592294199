import { Ref, computed } from "vue";
import {
    createFieldState,
    createFileState,
    createNullableFieldState,
    FormFields,
    FormParams,
} from "@/domains/app/forms";
import { Game, useGame } from "./games";

export type GameFormState = {
    formArgs: FormParams;
    data: Ref<Game | null>;
};

/**
 * Create form state descibing how to structure form with its configuration
 *
 * @param id id of the game in the form
 * @returns a form state
 */
export function useGameForm(id?: number): GameFormState {
    const { data: game, save, del, apiErrors } = useGame(id);

    const fields = getFields(game);

    const formArgs: FormParams = {
        title: "Création d'un gibier",
        driver: {
            submit: save,
        },
        fields,
        getErrors: () => apiErrors,
    };

    // If Edition mode
    if (id !== undefined && id >= 0) {
        formArgs.title = "Edition d'un gibier";
        formArgs.driver.delete = del;
    }

    const formState: GameFormState = {
        formArgs,
        data: game,
    };

    return formState;
}

/**
 * Map game props to form fields
 *
 * @param game game state
 * @returns form fields
 */
function getFields(game: Ref<Game | null>): FormFields {
    return [
        {
            label: "Nom du gibier",
            type: "text",
            name: "label",
            getState: () => createFieldState<string>(game, "label"),
        },
        {
            label: "Position dans le menu",
            type: "number",
            name: "order_num",
            getState: () => createNullableFieldState<string>(game, "order_num"),
        },
        {
            type: "static-img",
            value: computed(() => game.value?.links?.injuries_map || null),
        },
        {
            label: "Image des blessures",
            type: "file",
            name: "injuries_map",
            getState: () => createFileState(game, "injuries_map"),
        },
    ];
}

import { ref } from "vue";
import {
    Resource,
    ResourceState,
    resourceStatePrototype,
    useResourceState,
} from "@/domains/app/resource";
import { useUser } from "@/domains/auth/user";

export type Weapon = Resource & {
    label: string;
    type_id: number;
    user_id: number;
};

export type WeaponState = ResourceState<Weapon>;

/**
 * Create a weapon state to get data and its mutators
 * @param id Id of the weapon that the state should be related to
 * @returns a weapon state with the data and mutators
 */
export function useWeapon(id?: number): WeaponState {
    const state: WeaponState = {
        data: ref(null),
        apiRoute: "weapons",
        apiErrors: ref(null),
        save: () => resourceStatePrototype.save(state),
        del: () => resourceStatePrototype.del(state),
        init: () =>
            resourceStatePrototype.init(id, state, {
                id: -1,
                label: "",
                type_id: -1,
                user_id: useUser().user.value?.id || -1,
            }),
    };

    return useResourceState(state);
}

import { Ref, computed, ref, watch, ComputedRef, WatchStopHandle } from "vue";
import {
    createNullableFieldState,
    FormFields,
    FormParams,
    useObjectEntriesAsFieldValues,
    useRelationAsFieldValue,
} from "@/domains/app/forms";
import { Sheet, useSheet } from "./sheets";
import { createFieldState } from "@/domains/app/forms";
import {
    FAILURE_IDS,
    POSITIVE_CONTROL_IDS,
    SUCCESSFUL_IDS,
} from "@/domains/cruds/results/results";
import { PURSUIT_DOG_IDS } from "@/domains/cruds/pursuits/pursuits";
import { useGame } from "@/domains/cruds/games/games";
import { useRequesterForm } from "../requesters/forms";
import { useDogForm } from "../dogs/forms";
import { useLocationForm } from "../locations/forms";
import { usePositionForm } from "../positions/forms";
import { useHuntTypeForm } from "../huntTypes/forms";
import { useWeaponForm } from "../weapons/forms";
import { useUser } from "@/domains/auth/user";

export type SheetFormState = {
    formArgs: FormParams;
    data: Ref<Sheet | null>;
};

/**
 * Create form state descibing how to structure form with its configuration
 *
 * @param from id of the sheet in the form
 * @returns a form state
 */
export function useSheetForm(from?: number | Ref<Sheet>): SheetFormState {
    let id: number | undefined;
    if (typeof from === "object") {
        id = from.value.id;
    } else {
        id = from;
    }
    const { data: sheet, save, del: remove, apiErrors } = useSheet(from);

    const fields = getFields(sheet);
    const complementaryFields = getComplementaryFields(sheet);

    const user = useUser().user;
    const formArgs: FormParams = {
        title: "Création d'une fiche",
        driver: {
            submit: save,
        },
        fields,
        complementaryFields,
        getErrors: () => apiErrors,
        getDisabled: () =>
            computed(
                () =>
                    user.value !== null &&
                    sheet.value !== null &&
                    user.value.id !== sheet.value.user_id
            ),
    };

    // If Edition mode
    if (id !== undefined && id >= 0) {
        formArgs.title = "Edition d'une fiche";
        formArgs.driver.delete = remove;
    }

    const formState: SheetFormState = {
        formArgs,
        data: sheet,
    };

    return formState;
}

/**
 * Map sheet props to form fields
 *
 * @param sheet sheet state
 * @returns form fields
 */
function getFields(sheet: Ref<Sheet | null>): FormFields {
    const user = useUser().user;
    const gameInjuriesMap: Ref<string | null> = ref(null);
    let stopGameWatcher: WatchStopHandle | null = null;

    const currSeasonStart = new Date();
    currSeasonStart.setDate(1);
    currSeasonStart.setMonth(6);
    currSeasonStart.setMilliseconds(0);
    currSeasonStart.setSeconds(0);
    currSeasonStart.setMinutes(0);
    currSeasonStart.setHours(0);
    if (currSeasonStart > new Date()) {
        currSeasonStart.setFullYear(currSeasonStart.getFullYear() - 1);
    }
    const sheetGameId: ComputedRef<number | undefined> = computed(
        () => sheet.value?.game_id
    );

    watch(sheetGameId, (value) => {
        gameInjuriesMap.value = null;
        if (!value) {
            return;
        }
        if (stopGameWatcher) {
            stopGameWatcher();
        }
        stopGameWatcher = watch(useGame(value).data, (game) => {
            if (game && game.injuries_map) {
                gameInjuriesMap.value = game.links.injuries_map;
            }
        });
    });

    const injuriesVisibility = computed(() =>
        sheet.value?.result_id
            ? SUCCESSFUL_IDS.includes(sheet.value.result_id) ||
              POSITIVE_CONTROL_IDS.includes(sheet.value.result_id) ||
              FAILURE_IDS.includes(sheet.value.result_id)
            : false
    );

    const injuriesState = createFieldState<
        {
            injury_id: number;
            priority: number;
        }[]
    >(sheet, "injuries");

    const depResStateQueryParams = ref<Record<string, string>>({});
    const sheetDep = computed<Sheet["department_id"] | undefined>(
        () => sheet.value?.department_id
    );

    watch(
        sheetDep,
        (depId) => {
            if (depId === undefined || depId <= 0)
                return (depResStateQueryParams.value = {});
            depResStateQueryParams.value = {
                department_id: "" + depId,
                scope: encodeURIComponent("user+in_department"),
            };
        },
        { immediate: true }
    );

    const fields: FormFields = [
        {
            type: "static-text",
            value: computed(() => "Fiche de " + sheet.value?.meta?.user?.name),
            getVisibility: () =>
                computed(
                    () =>
                        sheet.value?.user_id !== undefined &&
                        user.value !== null &&
                        user?.value.id !== sheet.value?.user_id &&
                        sheet.value.meta?.user !== undefined
                ),
        },
        {
            label: "Date",
            type: "date",
            name: "date",
            markAsRequired: true,
            getState: () => createFieldState<string>(sheet, "date"),
            getWarning: () =>
                computed(() =>
                    sheet.value?.date !== undefined &&
                    sheet.value.date !== "" &&
                    new Date(sheet.value.date).getTime() <
                        currSeasonStart.getTime()
                        ? "Attention, vous saisissez une fiche pour une saison passée"
                        : null
                ),
        },
        {
            label: "Chien utilisé",
            type: "paginated-select",
            name: "dog_id",
            getState: () => createFieldState<number>(sheet, "dog_id"),
            markAsRequired: true,
            isRelation: true,
            formFactory: useDogForm,
        },
        {
            label: "Pays",
            type: "choice",
            name: "department_id",
            getAllowedValues: () =>
                useRelationAsFieldValue("departments", "label", false, {
                    sort: "most-used",
                }),
            getState: () => createFieldState<number>(sheet, "department_id"),
            markAsRequired: true,
        },
        {
            label: "Demandeur",
            type: "paginated-select",
            name: "requester_id",
            getQueryData: () => computed(() => depResStateQueryParams.value),
            getState: () =>
                createNullableFieldState<number>(sheet, "requester_id"),
            isRelation: true,
            formFactory: useRequesterForm,
            getVisibility: () =>
                computed(
                    () =>
                        sheet.value?.department_id !== undefined &&
                        sheet.value.department_id > 0
                ),
            defaultHidden: null,
            defaultVisible: null,
        },
        {
            label: "Lieu",
            type: "paginated-select",
            name: "location_id",
            getQueryData: () => computed(() => depResStateQueryParams.value),
            getState: () =>
                createNullableFieldState<number>(sheet, "location_id"),
            isRelation: true,
            formFactory: useLocationForm,
            getVisibility: () =>
                computed(
                    () =>
                        sheet.value?.department_id !== undefined &&
                        sheet.value.department_id > 0
                ),
            defaultHidden: null,
            defaultVisible: null,
        },
        // {
        //     label: "GIC",
        //     type: "paginated-select",
        //     name: "gic_id",
        //     getQueryData: () => computed(() => depResStateQueryParams.value),
        //     getState: () => createNullableFieldState<number>(sheet, "gic_id"),
        //     getVisibility: () =>
        //         computed(
        //             () =>
        //                 sheet.value?.department_id !== undefined &&
        //                 sheet.value.department_id > 0
        //         ),
        //     isRelation: true,
        //     formFactory: useGicForm,
        //     defaultHidden: null,
        //     defaultVisible: null,
        // },
        {
            label: "Type de chasse",
            type: "choice",
            name: "hunt_id",
            getAllowedValues: () => useRelationAsFieldValue("hunts", "label"),
            getState: () => createFieldState<number>(sheet, "hunt_id"),
            markAsRequired: true,
        },
        {
            label: "Animal blessé dérangé",
            type: "boolean",
            name: "disturbed",
            getState: () => createFieldState<boolean>(sheet, "disturbed"),
            helpText:
                "blessé poursuivi par chien de traque, recherché avec insistance par le chasseur, ou recherché par un équipage non agréé",
        },
        {
            label: "Résultat",
            type: "choice",
            name: "result_id",
            getAllowedValues: () => useRelationAsFieldValue("results", "label"),
            getState: () => createFieldState<number>(sheet, "result_id"),
            markAsRequired: true,
        },
        {
            label: "Gibier",
            type: "choice",
            name: "game_id",
            getAllowedValues: () => useRelationAsFieldValue("games", "label"),
            getState: () => createFieldState<number>(sheet, "game_id"),
            markAsRequired: true,
        },
        {
            label: "Sexe",
            type: "choice",
            name: "gender",
            getAllowedValues: () =>
                useObjectEntriesAsFieldValues({
                    M: "Mâle",
                    F: "Femelle",
                    U: "Indéterminé",
                }),
            getState: () => createFieldState<"M" | "F" | "U">(sheet, "gender"),
            markAsRequired: () =>
                computed(
                    () => !FAILURE_IDS.includes(sheet.value?.result_id || -1)
                ),
        },
        {
            label: "Age",
            type: "choice",
            name: "age",
            getAllowedValues: () =>
                useObjectEntriesAsFieldValues({
                    "1INF": "age < 1 an",
                    "1SUP": "age >= 1 an",
                    U: "Indéterminé",
                }),
            getState: () =>
                createFieldState<"1INF" | "1SUP" | "U">(sheet, "age"),
            markAsRequired: () =>
                computed(
                    () => !FAILURE_IDS.includes(sheet.value?.result_id || -1)
                ),
        },
        {
            label: "Poids vidé",
            type: "number",
            name: "weight",
            getState: () => createNullableFieldState<number>(sheet, "weight"),
            getVisibility: () =>
                // Visible only if result is successful
                computed(() =>
                    sheet.value?.result_id
                        ? SUCCESSFUL_IDS.includes(sheet.value?.result_id)
                        : false
                ),
            defaultHidden: null,
            defaultVisible: null,
            markAsRequired: true,
        },
        {
            label: "Tir",
            type: "choice",
            name: "shot_id",
            getAllowedValues: () => useRelationAsFieldValue("shots", "label"),
            getState: () => createFieldState<number>(sheet, "shot_id"),
            markAsRequired: true,
        },

        {
            label: "Age de la piste (h)",
            type: "number",
            name: "path_age",
            getState: () => createFieldState<number>(sheet, "path_age"),
            markAsRequired: true,
            getWarning: () =>
                computed(() =>
                    sheet.value && sheet.value?.path_age > 30
                        ? "Attention, l'age de la piste est élevé"
                        : ""
                ),
        },
        {
            label: "Longueur de pistage (m)",
            type: "number",
            name: "leash_length",
            getState: () => createFieldState<number>(sheet, "leash_length"),
            markAsRequired: true,
            getWarning: () =>
                computed(() =>
                    sheet.value && sheet.value?.leash_length > 5000
                        ? "Attention, la longueur de pistage est élevée"
                        : ""
                ),
        },
        {
            label: "Durée de pistage",
            type: "time:h:m",
            name: "duration",
            getState: () => createFieldState<string>(sheet, "duration"),
            markAsRequired: true,
            getWarning: () =>
                computed(() =>
                    sheet.value?.duration &&
                    parseInt(sheet.value?.duration.split(":")[0]) >= 5
                        ? "Attention, la durée de pistage est élevée"
                        : ""
                ),
        },
        {
            label: "Poursuite",
            type: "choice",
            name: "pursuit_id",
            getAllowedValues: () =>
                useRelationAsFieldValue("pursuits", "label", true),
            getState: () =>
                createNullableFieldState<number>(sheet, "pursuit_id"),
            getVisibility: () =>
                computed(() => {
                    const show = sheet.value?.result_id
                        ? SUCCESSFUL_IDS.includes(sheet.value.result_id) ||
                          FAILURE_IDS.includes(sheet.value.result_id)
                        : false;
                    return show;
                }),
            defaultHidden: null,
            defaultVisible: null,
            markAsRequired: true,
        },
        {
            label: "Longueur de la poursuite (m)",
            type: "number",
            name: "pursuit_length",
            getState: () => createFieldState<number>(sheet, "pursuit_length"),
            getVisibility: () =>
                computed(() =>
                    sheet.value?.pursuit_id
                        ? PURSUIT_DOG_IDS.includes(sheet.value?.pursuit_id)
                        : false
                ),
            defaultHidden: null,
            defaultVisible: null,
            markAsRequired: true,
            getWarning: () =>
                computed(() =>
                    sheet.value?.pursuit_length &&
                    sheet.value.pursuit_length > 2000
                        ? "Attention, la longueur de poursuite est élevée"
                        : ""
                ),
        },
        {
            label: "Durée de poursuite",
            type: "time:h:m",
            name: "pursuit_duration",
            getState: () =>
                createNullableFieldState<number>(sheet, "pursuit_duration"),
            getVisibility: () =>
                computed(() =>
                    sheet.value?.pursuit_id
                        ? PURSUIT_DOG_IDS.includes(sheet.value?.pursuit_id)
                        : false
                ),
            defaultHidden: null,
            defaultVisible: null,
            markAsRequired: true,
            getWarning: () =>
                computed(() =>
                    sheet.value?.pursuit_duration &&
                    parseInt(sheet.value?.pursuit_duration.split(":")[0]) >= 2
                        ? "Attention, la durée de poursuite est élevée"
                        : ""
                ),
        },
        {
            label: "Causes d'échec",
            type: "choice-multiple-prioritized",
            name: "failures",
            getAllowedValues: () =>
                useRelationAsFieldValue("failures", "label"),
            getState: () =>
                createFieldState<
                    {
                        failure_id: number;
                        priority: number;
                    }[]
                >(sheet, "failures"),
            getVisibility: () =>
                computed(() =>
                    sheet.value?.result_id
                        ? FAILURE_IDS.includes(sheet.value?.result_id)
                        : false
                ),
            allowSame: false,
            column: "failure_id",
            count: 2,
            singularLabel: "Cause d'échec",
            defaultHidden: [],
            defaultVisible: [],
            markAsRequired: true,
        },
        {
            type: "static-img",
            value: gameInjuriesMap,
            getVisibility: () => injuriesVisibility,
        },
        {
            label: "Blessure(s)",
            type: "choice-multiple-prioritized",
            name: "injuries",
            getAllowedValues: () =>
                useRelationAsFieldValue("injuries", "label"),
            getState: () => injuriesState,
            getVisibility: () => injuriesVisibility,
            defaultHidden: [],
            defaultVisible: [],
            allowSame: false,
            column: "injury_id",
            count: 2,
            singularLabel: "Blessure",
            markAsRequired: true,
        },
        {
            label: "Zones de blessures",
            type: "slot",
            name: "injuries-zones",
            getVisibility: () => injuriesVisibility,
            getState: () => injuriesState,
        },
        {
            type: "slot",
            getState: () => ref(null),
            label: "",
            name: "paid_trip_spacer",
        },
        {
            label: "KM Véhicule",
            type: "number",
            name: "vehicule_km",
            markAsRequired: true,
            getState: () => createFieldState<number>(sheet, "vehicule_km"),
            getWarning: () =>
                computed(() =>
                    sheet.value?.vehicule_km && sheet.value.vehicule_km > 200
                        ? "Attention, les kilomètres sont élevés"
                        : ""
                ),
        },
    ];

    if (process.env.VUE_APP_ENABLE_PAID_TRIPS === "true") {
        const isPaidTrip = computed<boolean>(
            () => sheet.value?.paid_trip || false
        );
        fields.push({
            label: "Bénéficier de la déduction des frais kilométriques",
            type: "boolean",
            name: "paid_trip",
            getVisibility: () =>
                computed(
                    () =>
                        (sheet.value?.vehicule_km || 0) > 0 &&
                        user.value?.allow_paid_trips === true
                ),
            defaultHidden: false,
            defaultVisible: false,
            getState: () => createFieldState<boolean>(sheet, "paid_trip"),
        });
        fields.push({
            label: "Nom et prénom du demandeur ou témoin",
            type: "text",
            name: "paid_trip_observer",
            markAsRequired: true,
            getVisibility: () => isPaidTrip,
            defaultHidden: null,
            defaultVisible: "",
            getState: () =>
                createFieldState<string>(sheet, "paid_trip_observer"),
        });
        fields.push({
            label: "Téléphone demandeur ou témoin",
            type: "text",
            name: "paid_trip_observer_phone",
            markAsRequired: true,
            getVisibility: () => isPaidTrip,
            defaultHidden: null,
            defaultVisible: "",
            getState: () =>
                createFieldState<string>(sheet, "paid_trip_observer_phone"),
        });
        fields.push({
            label: "Commune du début de la recherche",
            type: "text",
            name: "paid_trip_city",
            markAsRequired: true,
            getVisibility: () => isPaidTrip,
            defaultHidden: null,
            defaultVisible: "",
            getState: () => createFieldState<string>(sheet, "paid_trip_city"),
        });
        fields.push({
            label: "Code postal de la commune",
            type: "text",
            name: "paid_trip_postal_code",
            markAsRequired: true,
            getVisibility: () => isPaidTrip,
            defaultHidden: null,
            defaultVisible: "",
            getState: () =>
                createFieldState<string>(sheet, "paid_trip_postal_code"),
        });
    }

    return fields;
}

function getComplementaryFields(sheet: Ref<Sheet | null>): FormFields {
    return [
        {
            label: "Météo",
            type: "choice",
            name: "weather_id",
            getAllowedValues: () => useRelationAsFieldValue("weather", "label"),
            getState: () =>
                createNullableFieldState<number>(sheet, "weather_id"),
        },
        {
            label: "GPS",
            type: "text",
            name: "gps",
            getState: () => createNullableFieldState<string>(sheet, "gps"),
        },
        {
            label: "Poste",
            type: "paginated-select",
            name: "position_id",
            getState: () =>
                createNullableFieldState<number>(sheet, "position_id"),
            isRelation: true,
            formFactory: usePositionForm,
        },
        {
            label: "Traque",
            type: "paginated-select",
            name: "hunt_type_id",
            getState: () =>
                createNullableFieldState<number>(sheet, "hunt_type_id"),
            isRelation: true,
            formFactory: useHuntTypeForm,
        },
        {
            label: "Arme",
            type: "paginated-select",
            name: "weapon_id",
            getState: () =>
                createNullableFieldState<number>(sheet, "weapon_id"),
            isRelation: true,
            formFactory: useWeaponForm,
        },
        {
            label: "Calibre",
            type: "choice",
            name: "calibre_id",
            getAllowedValues: () =>
                useRelationAsFieldValue("calibres", "label"),
            getState: () =>
                createNullableFieldState<number>(sheet, "calibre_id"),
        },
        {
            label: "Munition",
            type: "choice",
            name: "ammunition_id",
            getAllowedValues: () =>
                useRelationAsFieldValue("ammunitions", "label"),
            getState: () =>
                createNullableFieldState<number>(sheet, "ammunition_id"),
        },
        {
            label: "Distance de tir",
            type: "number",
            name: "shot_distance",
            getState: () =>
                createNullableFieldState<number>(sheet, "shot_distance"),
        },
        {
            label: "Visées",
            type: "choice",
            name: "aim_id",
            getAllowedValues: () => useRelationAsFieldValue("aims", "label"),
            getState: () => createNullableFieldState<number>(sheet, "aim_id"),
        },
        {
            label: "Allure du gibier",
            type: "choice",
            name: "pace_id",
            getAllowedValues: () => useRelationAsFieldValue("paces", "label"),
            getState: () => createNullableFieldState<number>(sheet, "pace_id"),
        },
        {
            label: "Zone libre",
            type: "text",
            name: "description",
            getState: () =>
                createNullableFieldState<string>(sheet, "description"),
            getWarning: () =>
                computed(() =>
                    (sheet.value?.description?.length || 0) > 255
                        ? "Ne pas dépasser les 255 caractères (" +
                          (sheet.value?.description?.length || 0) +
                          ")"
                        : null
                ),
        },
        {
            label: "Bracelet",
            type: "number",
            name: "bracelet_num",
            getState: () =>
                createNullableFieldState<number>(sheet, "bracelet_num"),
            getVisibility: () =>
                computed(() =>
                    sheet.value?.result_id
                        ? SUCCESSFUL_IDS.includes(sheet.value?.result_id)
                        : false
                ),
            defaultHidden: null,
            defaultVisible: null,
        },
    ];
}


import { defineComponent, watch } from "@vue/runtime-core";
import { ref } from "@vue/reactivity";
import Nav from "@/domains/app/components/Nav.vue";
import { dispatch, onMessage } from "./domains/app/bus";
import { useUser } from "./domains/auth/user";
import Flashes from "./domains/flashes/components/Flashes.vue";
import Dialogs from "./domains/app/dialogs/components/Dialogs.vue";
import { useMetaTitle } from "./domains/app/pageMeta";
import { FormParams, ResourceFormStateFactory } from "./domains/app/forms";
import Modal from "./domains/app/components/Modal.vue";
import Form from "./domains/app/components/Form.vue";

export default defineComponent({
    components: {
        Nav,
        Flashes,
        Dialogs,
        Modal,
        Form,
    },

    setup() {
        const appOffset = ref("0");
        onMessage<string>("nav-toggle-update-width", (val) => {
            appOffset.value = val;
        });
        watch(
            useMetaTitle().title,
            (title) => {
                document.title = title;
            },
            { immediate: true }
        );
        let modalKey: string;

        const modalFormProps = ref<FormParams>();
        onMessage<{
            factory: ResourceFormStateFactory<any>;
            key: string;
        }>("field.create.relation", ({ factory, key }) => {
            const formProps = factory(undefined);
            modalKey = key;
            modalFormProps.value = formProps.formArgs;
        });

        const { user, connectedAs } = useUser();

        return {
            appOffset,
            user,
            connectedAs,
            modalFormProps,
            onRelationAdded() {
                modalFormProps.value = undefined;
                dispatch("field.create.relation.created", modalKey);
            },
            isIntegrationEnv: process.env.VUE_APP_IS_INTEGRATION === "true",
        };
    },
});

import { ref } from "vue";
import {
    Resource,
    ResourceState,
    resourceStatePrototype,
    useResourceState,
} from "@/domains/app/resource";
import { useUser } from "@/domains/auth/user";

export type Position = Resource & {
    label: string;
    user_id: number;
};

export type PositionState = ResourceState<Position>;

/**
 * Create a position state to get data and its mutators
 * @param id Id of the position that the state should be related to
 * @returns a position state with the data and mutators
 */
export function usePosition(id?: number): PositionState {
    const state: PositionState = {
        data: ref(null),
        apiRoute: "positions",
        apiErrors: ref(null),
        save: () => resourceStatePrototype.save(state),
        del: () => resourceStatePrototype.del(state),
        init: () =>
            resourceStatePrototype.init(id, state, {
                id: -1,
                label: "",
                user_id: useUser().user.value?.id || -1,
            }),
    };

    return useResourceState(state);
}

import { ref } from "vue";
import {
    Resource,
    ResourceState,
    resourceStatePrototype,
    useResourceState,
} from "@/domains/app/resource";

export type Injury = Resource & {
    label: string;
    available: boolean;
    zones: number[];
};

export type InjuryState = ResourceState<Injury>;

/**
 * Create a injury state to get data and its mutators
 * @param id Id of the injury that the state should be related to
 * @returns a injury state with the data and mutators
 */
export function useInjury(id?: number): InjuryState {
    const state: InjuryState = {
        data: ref(null),
        apiRoute: "injuries",
        apiErrors: ref(null),
        save: () => resourceStatePrototype.save(state),
        del: () => resourceStatePrototype.del(state),
        init: () =>
            resourceStatePrototype.init(id, state, {
                id: -1,
                label: "",
                available: true,
                zones: [],
            }),
    };

    return useResourceState(state);
}

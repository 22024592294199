import { Ref } from "vue";
import { createFieldState, FormFields, FormParams } from "@/domains/app/forms";
import { Shot, useShot } from "./shots";

export type ShotFormState = {
    formArgs: FormParams;
    data: Ref<Shot | null>;
};

/**
 * Create form state descibing how to structure form with its configuration
 *
 * @param id id of the shot in the form
 * @returns a form state
 */
export function useShotForm(id?: number): ShotFormState {
    const { data: shot, save, del: remove, apiErrors } = useShot(id);

    const fields = getFields(shot);

    const formArgs: FormParams = {
        title: "Création d'un tir",
        driver: {
            submit: save,
        },
        fields,
        getErrors: () => apiErrors,
    };

    // If Edition mode
    if (id !== undefined && id >= 0) {
        formArgs.title = "Edition d'un tir";
        formArgs.driver.delete = remove;
    }

    const formState: ShotFormState = {
        formArgs,
        data: shot,
    };

    return formState;
}

/**
 * Map shot props to form fields
 *
 * @param shot shot state
 * @returns form fields
 */
function getFields(shot: Ref<Shot | null>): FormFields {
    return [
        {
            label: "Label du tir",
            type: "text",
            name: "label",
            getState: () => createFieldState<string>(shot, "label"),
        },
        {
            label: "Disponible pour la saisie",
            type: "boolean",
            name: "enabled",
            getState: () => createFieldState<boolean>(shot, "enabled"),
        },
    ];
}

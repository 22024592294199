import { computed, Ref, WritableComputedRef } from "vue";
import { FormFields, FormParams } from "@/domains/app/forms";
import { Weather, useWeather } from "./weather";

export type WeatherFormState = {
    formArgs: FormParams;
    data: Ref<Weather | null>;
};

/**
 * Create form state descibing how to structure form with its configuration
 *
 * @param id id of the weather in the form
 * @returns a form state
 */
export function useWeatherForm(id?: number): WeatherFormState {
    const { data: weather, save, del, apiErrors } = useWeather(id);

    const fields = getFields(weather);

    const formArgs: FormParams = {
        title: "Création d'une condition de météo",
        driver: {
            submit: save,
        },
        fields,
        getErrors: () => apiErrors,
    };

    // If Edition mode
    if (id !== undefined && id >= 0) {
        formArgs.title = "Edition d'une condition de météo";
        formArgs.driver.delete = del;
    }

    const formState: WeatherFormState = {
        formArgs,
        data: weather,
    };

    return formState;
}

/**
 * Map weather props to form fields
 *
 * @param weather weather state
 * @returns form fields
 */
function getFields(weather: Ref<Weather | null>): FormFields {
    const labelState: WritableComputedRef<string> = computed<string>({
        get: () => (weather.value ? weather.value.label : ""),
        set: (val: string) => {
            if (weather.value) weather.value.label = val;
        },
    });

    return [
        {
            label: "Libellé",
            type: "text",
            name: "label",
            getState: () => labelState,
        },
    ];
}

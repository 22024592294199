import { ref, Ref } from "vue";
import { createFieldState, FormFields, FormParams } from "@/domains/app/forms";
import { Faq, useFaq } from "./faqs";

export type FaqFormState = {
    formArgs: FormParams;
    data: Ref<Faq | null>;
};

/**
 * Create form state descibing how to structure form with its configuration
 *
 * @param id id of the faq in the form
 * @returns a form state
 */
export function useFaqForm(id?: number): FaqFormState {
    const { data: faq, save, del, apiErrors } = useFaq(id);

    const fields = getFields(faq);

    const formArgs: FormParams = {
        title: "Création d'une cause d'échec",
        driver: {
            submit: save,
        },
        fields,
        getErrors: () => apiErrors,
    };

    // If Edition mode
    if (id !== undefined && id >= 0) {
        formArgs.title = "Edition d'une cause d'échec";
        formArgs.driver.delete = del;
    }

    const formState: FaqFormState = {
        formArgs,
        data: faq,
    };

    return formState;
}

/**
 * Map faq props to form fields
 *
 * @param faq faq state
 * @returns form fields
 */
function getFields(faq: Ref<Faq | null>): FormFields {
    return [
        {
            type: "static-text",
            value: ref(
                "La FAQ est publique et visible par tous y compris les non-adhérents."
            ),
        },
        {
            label: "Question",
            type: "text",
            name: "question",
            getState: () => createFieldState(faq, "question"),
        },
        {
            label: "Réponse",
            type: "text",
            name: "answer",
            getState: () => createFieldState(faq, "answer"),
        },
    ];
}

import { computed, ComputedRef, ref, Ref } from "vue";
import { RouteLocation, Router } from "vue-router";
import { ErrorHandler, request, setKey } from "@/domains/api/api";
import { useLoading } from "@/domains/app/loading";
import {
    Ability,
    ROLE_ADMIN,
    ROLE_DELEGATE,
    User,
} from "@/domains/cruds/users/users";
import { useFlashes } from "../flashes/flashes";
import { getMessage } from "../app/translation";

type LoginResponse = {
    user: User;
    token: string;
    tokenExpiresAt: number;
    abilities: Ability[];
    connectedAs: boolean;
};

const user: Ref<null | User> = ref(null);
let resolveOnLogin: (u: User) => void;
const loginPromise = new Promise((r) => (resolveOnLogin = r));

let refreshTokenTimeout: number | null = null;
const connectedAs = ref(false);

function setUser(
    token: string | null,
    expiresAt: null | number,
    newUser?: User | null
) {
    connectedAs.value = false;
    if (newUser !== undefined) {
        user.value = newUser;
        if (user.value && resolveOnLogin) resolveOnLogin(user.value);
    }
    setKey(token, expiresAt);
    if (refreshTokenTimeout) {
        window.clearTimeout(refreshTokenTimeout);
        refreshTokenTimeout = null;
    }
    if (token && expiresAt) {
        refreshTokenTimeout = setTimeout(() => {
            refreshToken();
        }, expiresAt - new Date().getTime() - 30000);
    }
}

async function login(
    memberNum: string,
    password: string,
    showErr?: ErrorHandler
): Promise<boolean> {
    const { setLoading } = useLoading("login");
    setLoading(true);

    return request<LoginResponse>("token", "post", showErr, {
        member_num: memberNum,
        password: password,
    })
        .then((data) => {
            if (data) {
                if (data.user.disabled) {
                    useFlashes().addFlash(
                        getMessage("global.errors.403.account-disabled"),
                        "error"
                    );
                } else {
                    setUser(data.token, data.tokenExpiresAt, {
                        ...data.user,
                        abilities: data.abilities,
                    });
                    connectedAs.value = data.connectedAs;
                    return true;
                }
            }
            return false;
        })
        .finally(() => {
            setLoading(false);
        });
}

async function refreshToken() {
    if (refreshTokenTimeout) {
        window.clearTimeout(refreshTokenTimeout);
        refreshTokenTimeout = null;
    }
    const rs = await request<{
        token: string;
        tokenExpiresAt: null | number;
    }>("token/refresh", "post");
    if (rs) {
        setUser(rs.token, rs.tokenExpiresAt);
    }
}

async function logout(router?: Router, showErr?: ErrorHandler) {
    try {
        await request("me/tokens", "delete", showErr).then(() =>
            router?.push({ name: "auth.login" })
        );
    } catch (_e) {}
    setUser(null, null, null);
}

export function resetPassword(
    password: string,
    token: string,
    email: string,
    showErr: ErrorHandler
): Promise<void> {
    return request<{ token: string; tokenExpiresAt: number | null }>(
        "reset-password",
        "post",
        showErr,
        {
            password,
            email,
            token,
        }
    ).then((data) => {
        if (data && data.token && data.tokenExpiresAt) {
            return fetchAndSetUserFromToken(data.token, data.tokenExpiresAt);
        }
    });
}

export async function initializeUserFromLocalStorage(): Promise<void> {
    let token: string, tokenExpiresAt: number;
    try {
        token = JSON.parse(localStorage.getItem("api-key") || "null");
        tokenExpiresAt = JSON.parse(
            localStorage.getItem("api-key-expiration") || "null"
        );
    } catch (e) {
        return logout();
    }
    if (!token) return;
    if (tokenExpiresAt && tokenExpiresAt <= new Date().getTime())
        return logout();
    return fetchAndSetUserFromToken(
        token,
        tokenExpiresAt,
        "fetch-user-from-localstorage"
    );
}

export async function fetchAndSetUserFromToken(
    token: string,
    tokenExpiresAt: number,
    loadingKey = "fetch-user"
): Promise<void> {
    setKey(token, tokenExpiresAt);
    const { setLoading } = useLoading(loadingKey);
    setLoading(true);
    await request<{ user: User; abilities: Ability[] }>("me", "get")
        .then((data) => {
            if (!data) return logout();
            setUser(
                token,
                tokenExpiresAt,
                data?.user ? { ...data.user, abilities: data.abilities } : null
            );
        })
        .catch(() => {
            logout();
        });
    setLoading(false);
}

function canAccess(route: RouteLocation): boolean {
    if (route.meta?.guest) return true;
    const canAccess = (route?.meta?.abilities as Ability[])?.every(
        (requiredAbility) =>
            user.value?.abilities?.includes(requiredAbility) || false
    );
    const validCb =
        !route?.meta?.canAccessCb ||
        (route.meta.canAccessCb as (u: User | null) => boolean)(user.value);

    return validCb && (canAccess === true || canAccess === undefined);
}

export function useUser(): {
    user: ComputedRef<User | null>;
    login: typeof login;
    logout: typeof logout;
    canAccess: typeof canAccess;
    onLogin: typeof loginPromise;
    connectedAs: ComputedRef<boolean>;
    scope: ComputedRef<null | string>;
} {
    return {
        login,
        logout,
        canAccess,
        user: computed(() => user.value),
        onLogin: loginPromise,
        connectedAs: computed(() => connectedAs.value),
        scope: computed(() => {
            let scope = "user";
            if (user.value?.roles.includes(ROLE_ADMIN)) scope = "country";
            else if (user.value?.roles.includes(ROLE_DELEGATE))
                scope = "department";
            return scope;
        }),
    };
}

export function loginAs(id: User["id"]): Promise<void> {
    if (id === user.value?.id) return Promise.resolve();

    const { setLoading } = useLoading("login");
    const { addFlash } = useFlashes();
    return request<LoginResponse>(`users/${id}/token`, "post")
        .then((data) => {
            if (data) {
                setUser(data.token, data.tokenExpiresAt, {
                    ...data.user,
                    abilities: data.abilities,
                });
                connectedAs.value = data.connectedAs;
                addFlash(
                    `Connecté en tant que ${user.value?.first_name} ${user.value?.last_name} (${user.value?.member_num})`,
                    "success"
                );
            }
        })
        .finally(() => {
            setLoading(false);
        });
}

import { ref, Ref } from "vue";
import {
    createFieldState,
    FormFields,
    FormParams,
    FormState,
} from "@/domains/app/forms";
import {
    ADMINISATRATION_LEVEL_DELEGATE,
    ADMINISATRATION_LEVEL_USER,
    Department,
    useDepartment,
} from "./departments";

export type DepartmentFormState = FormState<Department>;

/**
 * Create form state descibing how to structure form with its configuration
 *
 * @param id id of the department in the form
 * @returns a form state
 */
export function useDepartmentForm(id?: number): DepartmentFormState {
    const {
        data: department,
        save,
        del: remove,
        apiErrors,
    } = useDepartment(id);

    const fields = getFields(department);

    const formArgs: FormParams = {
        title: "Création d'un pays",
        driver: {
            submit: save,
        },
        fields,
        getErrors: () => apiErrors,
    };

    // If Edition mode
    if (id !== undefined && id >= 0) {
        formArgs.title = "Edition d'un pays";
        formArgs.driver.delete = remove;
    }

    const formState: DepartmentFormState = {
        formArgs,
        data: department,
    };

    return formState;
}

/**
 * Map department props to form fields
 *
 * @param dep department state
 * @returns form fields
 */
function getFields(dep: Ref<Department | null>): FormFields {
    return [
        {
            label: "Nom du pays",
            type: "text",
            name: "label",
            getState: () => createFieldState<string>(dep, "label"),
        },
        {
            label: "Code du pays",
            type: "text",
            name: "label",
            getState: () => createFieldState<string>(dep, "code"),
        },
        {
            label: "Administration (gics, demandeurs, lieux)",
            type: "choice",
            name: "administration_level",
            getAllowedValues: () =>
                ref([
                    {
                        key: ADMINISATRATION_LEVEL_DELEGATE,
                        value: "Par les délégués",
                    },
                    {
                        key: ADMINISATRATION_LEVEL_USER,
                        value: "Par les conducteurs",
                    },
                ]),
            getState: () =>
                createFieldState<number>(dep, "administration_level"),
        },
    ];
}

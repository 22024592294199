import { computed, Ref, WritableComputedRef } from "vue";
import { FormFields, FormParams } from "@/domains/app/forms";
import { Pace, usePace } from "./paces";

export type PaceFormState = {
    formArgs: FormParams;
    data: Ref<Pace | null>;
};

/**
 * Create form state descibing how to structure form with its configuration
 *
 * @param id id of the pace in the form
 * @returns a form state
 */
export function usePaceForm(id?: number): PaceFormState {
    const { data: pace, save, del, apiErrors } = usePace(id);

    const fields = getFields(pace);

    const formArgs: FormParams = {
        title: "Création d'une allure",
        driver: {
            submit: save,
        },
        fields,
        getErrors: () => apiErrors,
    };

    // If Edition mode
    if (id !== undefined && id >= 0) {
        formArgs.title = "Edition d'une allure";
        formArgs.driver.delete = del;
    }

    const formState: PaceFormState = {
        formArgs,
        data: pace,
    };

    return formState;
}

/**
 * Map pace props to form fields
 *
 * @param pace pace state
 * @returns form fields
 */
function getFields(pace: Ref<Pace | null>): FormFields {
    const labelState: WritableComputedRef<string> = computed<string>({
        get: () => (pace.value ? pace.value.label : ""),
        set: (val: string) => {
            if (pace.value) pace.value.label = val;
        },
    });

    return [
        {
            label: "Nom de l'allure",
            type: "text",
            name: "label",
            getState: () => labelState,
        },
    ];
}

import { computed, Ref, WritableComputedRef } from "vue";
import { FormFields, FormParams } from "@/domains/app/forms";
import { Calibre, useCalibre } from "./calibres";

export type CalibreFormState = {
    formArgs: FormParams;
    data: Ref<Calibre | null>;
};

/**
 * Create form state descibing how to structure form with its configuration
 *
 * @param id id of the calibre in the form
 * @returns a form state
 */
export function useCalibreForm(id?: number): CalibreFormState {
    const { data: calibre, save, del, apiErrors } = useCalibre(id);

    const fields = getFields(calibre);

    const formArgs: FormParams = {
        title: "Création d'un calibre",
        driver: {
            submit: save,
        },
        fields,
        getErrors: () => apiErrors,
    };

    // If Edition mode
    if (id !== undefined && id >= 0) {
        formArgs.title = "Edition d'un calibre";
        formArgs.driver.delete = del;
    }

    const formState: CalibreFormState = {
        formArgs,
        data: calibre,
    };

    return formState;
}

/**
 * Map calibre props to form fields
 *
 * @param calibre calibre state
 * @returns form fields
 */
function getFields(calibre: Ref<Calibre | null>): FormFields {
    const labelState: WritableComputedRef<string> = computed<string>({
        get: () => (calibre.value ? calibre.value.label : ""),
        set: (val: string) => {
            if (calibre.value) calibre.value.label = val;
        },
    });

    return [
        {
            label: "Nom du calibre",
            type: "text",
            name: "label",
            getState: () => labelState,
        },
    ];
}

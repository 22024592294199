import { computed, Ref, WritableComputedRef } from "vue";
import { FormFields, FormParams } from "@/domains/app/forms";
import { Pursuit, usePursuit } from "./pursuits";

export type PursuitFormState = {
    formArgs: FormParams;
    data: Ref<Pursuit | null>;
};

/**
 * Create form state descibing how to structure form with its configuration
 *
 * @param id id of the pursuit in the form
 * @returns a form state
 */
export function usePursuitForm(id?: number): PursuitFormState {
    const { data: pursuit, save, del, apiErrors } = usePursuit(id);

    const fields = getFields(pursuit);

    const formArgs: FormParams = {
        title: "Création d'une poursuite",
        driver: {
            submit: save,
        },
        fields,
        getErrors: () => apiErrors,
    };

    // If Edition mode
    if (id !== undefined && id >= 0) {
        formArgs.title = "Edition d'une poursuite";
        formArgs.driver.delete = del;
    }

    const formState: PursuitFormState = {
        formArgs,
        data: pursuit,
    };

    return formState;
}

/**
 * Map pursuit props to form fields
 *
 * @param pursuit pursuit state
 * @returns form fields
 */
function getFields(pursuit: Ref<Pursuit | null>): FormFields {
    const labelState: WritableComputedRef<string> = computed<string>({
        get: () => (pursuit.value ? pursuit.value.label : ""),
        set: (val: string) => {
            if (pursuit.value) pursuit.value.label = val;
        },
    });

    return [
        {
            label: "Nom de la poursuite",
            type: "text",
            name: "label",
            getState: () => labelState,
        },
    ];
}

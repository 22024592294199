
import { Ref, ref } from "@vue/reactivity";
import { computed, defineComponent } from "@vue/runtime-core";
import Form from "@/domains/app/components/Form.vue";
import { FormErrors, FormParams } from "@/domains/app/forms";
import { getMessage } from "@/domains/app/translation";
import { useUser } from "../user";
import { afterLoginRoute } from "@/domains/app/router/middleware";
import { useRouter } from "vue-router";

export default defineComponent({
    components: {
        Form,
    },
    setup() {
        const memberNum = ref("");
        const password = ref("");
        const router = useRouter();

        const formErrors: Ref<FormErrors | null> = ref(null);

        function showErr(err: FormErrors) {
            formErrors.value = {
                message: getMessage(
                    err.fields?.credentials?.length
                        ? err.fields.credentials[0]
                        : err.message
                ),
                fields: err.fields,
            };
        }

        const { login, user } = useUser();

        const formParams: FormParams = {
            driver: {
                submit: () =>
                    login(memberNum.value, password.value, showErr).then(() => {
                        if (afterLoginRoute.value?.name) {
                            router.push(afterLoginRoute.value);
                        } else {
                            router.push({ name: "index" });
                        }
                    }),
            },
            fields: [
                {
                    type: "static-text",
                    value: computed(
                        () =>
                            "Déjà connecté en tant que " +
                            user.value?.first_name +
                            " " +
                            user.value?.last_name
                    ),
                    getVisibility: () => computed(() => user.value !== null),
                },
                {
                    label: "Numéro d'adhérent",
                    type: "text",
                    getState: () => memberNum,
                    name: "member_num",
                },
                {
                    label: "Mot de passe",
                    type: "text:hidden",
                    getState: () => password,
                    name: "password",
                },
                {
                    type: "slot",
                    label: "",
                    getState: () => ref(null),
                    name: "custom-footer",
                },
            ],
            title: "Connexion",
            getErrors: () => formErrors,
            showSubmitedFlash: false,
        };

        return {
            formParams,
        };
    },
});


import { request } from "@/domains/api/api";
import Form from "@/domains/app/components/Form.vue";
import { loginAs, useUser } from "@/domains/auth/user";
import { useFlashes } from "@/domains/flashes/flashes";
import { defineComponent } from "vue";
import { RouterLink, useRoute, useRouter } from "vue-router";
import { useUserForm } from "../forms";
import { computed } from "@vue/reactivity";
import { ROLE_ADMIN } from "../users";

export default defineComponent({
    components: { Form, RouterLink },
    setup() {
        const route = useRoute();
        const userId: number = parseInt(
            (Array.isArray(route.params.id)
                ? route.params.id[0]
                : route.params.id) || "0"
        );

        const { formArgs: formParams, data: user } = useUserForm(userId);
        const { user: loggedInUser } = useUser();
        if (!loggedInUser.value?.roles?.includes(ROLE_ADMIN) === true) {
            delete formParams.driver.delete;
            delete formParams.driver.submit;
        }

        const formErrors = formParams.getErrors();
        const router = useRouter();

        return {
            user,
            isAdmin: computed(
                () => loggedInUser.value?.roles?.includes(ROLE_ADMIN) === true
            ),
            connectAs: () =>
                user.value
                    ? loginAs(user.value.id).then(() =>
                          router.push({ name: "index" })
                      )
                    : undefined,
            formParams,
            sendWelcomeEmail: () =>
                request(
                    "users/" + user.value?.id + "/emails/welcome",
                    "post",
                    (err) => {
                        formErrors.value = {
                            message: err.fields?.form
                                ? err.fields.form[0]
                                : err.message,
                            fields: {},
                        };
                    },
                    {
                        email: user.value?.email,
                    }
                ).then(() => {
                    useFlashes().addFlash("Email envoyé", "success");
                }),
        };
    },
});
